/* eslint-disable import/no-mutable-exports */
import { Cookies } from 'react-cookie';

// TODO: Remove this when we have a proper way to get the siteId
export let siteId: string =
  localStorage.getItem('siteId') || new Cookies().get('siteId') || '';

export function setSiteId(value: number) {
  siteId = String(value);
  localStorage.setItem('siteId', String(value));
  new Cookies().remove('siteId');
}
