import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Image } from 'react-bootstrap';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useObservationImage } from '../../../hooks/image';
import { ObservationProp } from '../../../typescript/observation/observation';
import { ImageLoader } from '../../elements/ImageLoader';
import {
  AnnotationRef,
  ImageWithAnnotation,
} from '../../elements/ImageWithAnnotation';

type Props = {
  isAnnotation: boolean;
  observation: ObservationProp;
  showLabel: boolean;
  onImageDimensionsChange?: (width: number, height: number) => void;
};

export const ObservationsModalImage = forwardRef<AnnotationRef | null, Props>(
  ({ isAnnotation, observation, showLabel, onImageDimensionsChange }, ref) => {
    // Context
    const { theme } = useThemeContext();
    const { imageUrl, isImageLoading, fetchImageUrl } = useObservationImage(
      observation,
      'original',
    );

    // Ref
    const annotationRef = useRef<AnnotationRef>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    // State
    const [isImageSizeKnown, setIsImageSizeKnown] = useState<boolean>(false);
    const [imageDimensions, setImageDimensions] = useState<{
      width: number;
      height: number;
    }>();

    useImperativeHandle(ref, () => annotationRef.current!);

    const calculateImageDimensions = useCallback(() => {
      const ref = isAnnotation ? annotationRef.current?.imageRef : imageRef;
      if (ref?.current) {
        const { width, height } = ref.current;
        if (
          imageDimensions?.width !== width ||
          imageDimensions?.height !== height
        ) {
          setImageDimensions({ width, height });
          if (onImageDimensionsChange) {
            onImageDimensionsChange(width, height);
          }
        }
      }
    }, [imageDimensions, onImageDimensionsChange, isAnnotation]);

    const onImageLoad = useCallback(() => {
      calculateImageDimensions();
      setIsImageSizeKnown(true);
    }, [calculateImageDimensions]);

    const onImageError = useCallback(() => {
      setIsImageSizeKnown(false);
      fetchImageUrl();
    }, [fetchImageUrl]);

    useEffect(() => {
      calculateImageDimensions();
      window.addEventListener('resize', calculateImageDimensions);
      return () =>
        window.removeEventListener('resize', calculateImageDimensions);
    }, [calculateImageDimensions]);

    useEffect(() => {
      if (!imageUrl) {
        setIsImageSizeKnown(false);
        fetchImageUrl();
      }
    }, [fetchImageUrl, imageUrl]);

    return isImageLoading || !imageUrl ? (
      <ImageLoader
        style={{
          width: imageDimensions?.width || '100%',
          height: imageDimensions?.height || '100%',
        }}
        className={`loading-image loading-image-${theme}`}
      />
    ) : (
      <div
        style={{
          width: isImageSizeKnown ? '100%' : imageDimensions?.width || '100%',
          height: isImageSizeKnown ? '100%' : imageDimensions?.height || '100%',
        }}
      >
        {isAnnotation ? (
          <ImageWithAnnotation
            ref={annotationRef}
            className="ph-no-capture add-cursor"
            containerWidth="100%"
            containerHeight="100%"
            hideLabel={!showLabel}
            isInteractive
            onLoad={onImageLoad}
            onError={onImageError}
            imageUrl={imageUrl}
            detections={observation.detections}
          />
        ) : (
          <Image
            ref={imageRef}
            onLoad={onImageLoad}
            onError={onImageError}
            width="100%"
            height="100%"
            src={imageUrl}
          />
        )}
      </div>
    );
  },
);

ObservationsModalImage.displayName = 'ObservationsModalImage';
