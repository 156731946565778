import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ScenarioLabel } from './ScenarioLabel';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  getCameraScenarios,
  sortScenarios,
} from '../../../lib/helpers/scenario';
import {
  Scenario,
  ScenarioName,
} from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { Button } from '../../elements/Button';

interface Props {
  hideParentModal: () => void;
}

export function ScenarioSettings({ hideParentModal }: Props) {
  const { theme } = useThemeContext();
  const { camera, gqlCamera, updateCamera } = useCameraContext();
  const { userScenarios } = useScenarioContext();
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const availableScenarios = useMemo(
    () =>
      sortScenarios(
        userScenarios.filter((scenario) => scenario.value !== 'ppe'),
      ),
    [userScenarios],
  );
  const cameraScenarios = useMemo(
    () => getCameraScenarios(availableScenarios, gqlCamera),
    [availableScenarios, gqlCamera],
  );

  const [activeScenarioNames, setActiveScenarioNames] = useState<
    Set<ScenarioName>
  >(new Set());

  const handleClose = useCallback(() => {
    hideParentModal();
    setShow(false);
  }, [hideParentModal]);

  const handleShow = () => {
    hideParentModal();
    setShow(true);
  };

  useEffect(() => {
    if (show) {
      setActiveScenarioNames(
        new Set(cameraScenarios.map((scenario) => scenario.value)),
      );
    }
  }, [show, cameraScenarios]);

  const handleAddScenario = useCallback(
    (scenario: Scenario) => {
      const newSet = new Set(activeScenarioNames);
      newSet.add(scenario.value);
      setActiveScenarioNames(newSet);
    },
    [activeScenarioNames],
  );

  const handleRemoveScenario = useCallback(
    (scenario: Scenario) => {
      const newSet = new Set(activeScenarioNames);
      newSet.delete(scenario.value);
      setActiveScenarioNames(newSet);
    },
    [activeScenarioNames],
  );

  const handleSaveChanges = useCallback(async () => {
    if (camera) {
      setLoading(true);
      await updateCamera({
        ...camera,
        scenarios: Array.from(activeScenarioNames),
      });
      setLoading(false);
    }

    handleClose();
  }, [activeScenarioNames, camera, updateCamera, handleClose]);

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">
        {`${i18n.t('camera.active_scenarios.title')} (${cameraScenarios.length})`}
      </p>
      <p className="sub-color">{i18n.t('camera.active_scenarios.body')}</p>

      <div className="scenario-tag">
        <ul>
          {cameraScenarios.map((item) => (
            <ScenarioLabel key={item.value} scenario={item} />
          ))}
        </ul>
      </div>
      {cameraScenarios.length === 0 && (
        <p>
          <i>{i18n.t('scenarios.no_active_scenarios.title')}</i>
        </p>
      )}

      <Button
        icon={AddIcon}
        variant={`outline-${theme}`}
        type="button"
        title={i18n.t('button.add_scenario')}
        onClick={handleShow}
      />

      <Modal
        centered
        contentClassName={theme}
        className="custom-modal"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            {i18n.t('modal.camera.edit_scenarios.title')}
          </Modal.Title>
          <Button
            icon={CancelIcon}
            variant="transparent"
            className="border-0"
            type="button"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="scenario-tag">
              <p className="mb-1 weight-600">
                {i18n.t('active_scenarios.title')}
              </p>
              <ul>
                {availableScenarios
                  .filter((scenario) => activeScenarioNames.has(scenario.value))
                  .map((item) => (
                    <ScenarioLabel
                      key={item.value}
                      scenario={item}
                      iconType="remove"
                      onClick={handleRemoveScenario}
                    />
                  ))}
              </ul>
            </Col>

            <Col md={12} className="scenario-tag">
              <p className="mb-1 weight-600">{i18n.t('all_scenarios.title')}</p>
              <ul>
                {availableScenarios
                  .filter(
                    (scenario) => !activeScenarioNames.has(scenario.value),
                  )
                  .map((item) => (
                    <ScenarioLabel
                      key={item.value}
                      scenario={item}
                      iconType="add"
                      onClick={handleAddScenario}
                    />
                  ))}
              </ul>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            title={i18n.t('button.back_to_settings')}
            className="button-with-loader"
            variant={`outline-${theme}`}
            type="button"
            onClick={handleClose}
          />

          <Button
            title={i18n.t('button.save_changes')}
            className="button-with-loader"
            variant="primary"
            type="button"
            onClick={handleSaveChanges}
            loading={loading}
            disabled={loading}
          />
        </Modal.Footer>
      </Modal>

      <hr />
    </Col>
  );
}
