import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../components/elements/Button';
import { InputField } from '../components/elements/InputField';
import { LoginContainer } from '../components/login/LoginContainer';
import { useAuthContext } from '../contextapi/AuthProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import i18n from '../utils/i18n';

export const LoginPage = () => {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { login, route } = useAuthContext();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const signup = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const onLogin = await login(email, password);
      if (onLogin) {
        navigate(route.from);
      } else {
        toast.error(i18n.t('toast.wrong_user_details'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoginContainer>
      <h5 className="mb-32">{i18n.t('login.body')}</h5>

      <Form onSubmit={signup}>
        <InputField
          className="mb-12"
          controlId="formEmail"
          type="email"
          required
          title={`${i18n.t('input.email.title')}`}
          placeholder={`${i18n.t('input.email.placeholder')}`}
          onChange={(e) => setEmail(e.target.value)}
        />

        <InputField
          className="mb-12"
          type="password"
          controlId="formPassword"
          title={`${i18n.t('input.password.title')}`}
          placeholder={`${i18n.t('input.password.placeholder')}`}
          onChange={(e) => setPassword(e.target.value)}
        />

        <div className="mb-12">
          <Link
            to="/forgot-password"
            className={`${theme === 'dark' ? 'white-color' : 'dark-color'}`}
            style={{ textDecoration: 'none' }}
          >
            <Button
              title={i18n.t('button.forgot')}
              variant="transparent"
              style={{ padding: 0 }}
            />
          </Link>
        </div>

        <Button
          title={i18n.t('button.sign_in')}
          className="button-with-loader w-100"
          variant="primary"
          type="submit"
          disabled={loading}
          loading={loading}
        />
      </Form>
    </LoginContainer>
  );
};
