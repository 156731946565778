import posthog, { Properties } from 'posthog-js';

let isInitialized = false;

export const initPosthog = () => {
  if (
    process.env.REACT_APP_DISABLE_POSTHOG !== 'true' &&
    process.env.REACT_APP_ENV !== 'production'
  ) {
    posthog.init('phc_6tyhhQwK8UgupZgUfMmi5ozG3T4z0StHIzAAta19PNl', {
      api_host: 'https://eu.posthog.com',
      opt_out_capturing_by_default: true,
    });

    isInitialized = true;
  }
};

export const Posthog = {
  identify: (id?: string, properties?: Properties) => {
    if (!isInitialized) return;

    posthog.identify(id, properties);
  },
  capture: (eventName: string, properties?: Properties | null) => {
    if (!isInitialized) return;

    posthog.capture(eventName, properties);
  },
  reset: () => {
    if (!isInitialized) return;

    posthog.reset();
  },
  optInTracking: () => {
    if (!isInitialized) return;

    posthog.opt_in_capturing();
  },
  optOutTracking: () => {
    if (!isInitialized) return;

    posthog.opt_out_capturing();
  },
};
