import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Loader } from '../components/elements/Loader';
import { UserForm } from '../components/settings/UserForm';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { GET_USERS_BY_ID } from '../graphql/queries/user';
import { UserProp } from '../typescript/user/user';
import i18n from '../utils/i18n';

export const ProfilePage = () => {
  const { theme } = useThemeContext();
  const [key, setKey] = useState<number>(0);
  const [user, setUser] = useState<UserProp | null>(null);

  const { slug } = useParams<{ slug: string }>();
  // GraphQL
  const { error, data } = useQuery(GET_USERS_BY_ID, {
    variables: {
      id: Number(slug),
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (data && data.users_by_pk) {
      const userData = data.users_by_pk as UserProp;
      setUser(userData);
    }
  }, [data, error, user]);

  return (
    <Row className="settings mt-32">
      <Col md={12}>
        {!user ? (
          <div className="loader-layout">
            <Loader main />
          </div>
        ) : (
          <>
            <div className="user-title mb-32">
              <p className="user-information-image">
                {`${user?.email}`.substring(0, 1)}
              </p>
              <h5 className="ph-no-capture">
                {user?.username ? user?.username : user?.email}
              </h5>
              {/* <p
                className={`
                user-role
                user-role-${user?.isAdmin}-${theme}
                user-role-${user?.isAdmin}
                rounded-pill border`}
              >
                {user?.isAdmin ? 'Admin' : 'User'}
              </p> */}
            </div>
            <Tabs
              activeKey={key}
              className={`nav-tabs-${theme} mb-32 d-flex justify-content-center`}
              onSelect={(k) => setKey(Number(k))}
            >
              <Tab eventKey={0} title={i18n.t('tab.user_information')}>
                {user && <UserForm user={user} />}
              </Tab>
            </Tabs>
          </>
        )}
      </Col>
    </Row>
  );
};
