/** @jsxImportSource @emotion/react */
import { useMutation } from '@apollo/client';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CompressObservationsTable } from './CompressObservationsTable';
import {
  ObservationActionEventHandler,
  ObservationActions,
} from './ObservationActions';
import { ObservationDetailsRow } from './ObservationDetailsRow';
import { ObservationsModalImage } from './ObservationsModalImage';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { UPDATE_OBSERVATION } from '../../../graphql/mutations';
import {
  ADD_OBSERVATION_BOOKMARK,
  DELETE_OBSERVATION_BOOKMARK,
  UPDATE_OBSERVATION_BY_ID,
} from '../../../graphql/mutations/observation';
import { GetObservationsQueryBuilder } from '../../../hooks/graphql/observations';
import { spacing } from '../../../scss/spacing';
import {
  defaultModalValue,
  ModalType,
} from '../../../typescript/components/modal';
import { ObservationProp } from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import { isEmptyObject } from '../../../utils/typeUtils';
import { DangerZoneOverlay } from '../../cameras/settings/danger-zone/DangerZoneOverlay';
import { Button } from '../../elements/Button';
import { CustomSwitch } from '../../elements/CustomSwitch';
import { ImageDownloader } from '../../elements/ImageDownloader';
import { AnnotationRef } from '../../elements/ImageWithAnnotation';
import { ShortCutOverlay } from '../../observation-quick-view/ShortCutOverlay';
import { ReportFalseModal } from '../ReportFalseModal';

type Props = {
  observation: ObservationProp;
  buildGetObservationsQuery: GetObservationsQueryBuilder;

  hasAnnotations: boolean;
  hideLabel?: boolean;
  paginationDefaults?: {
    pageNumber?: number;
    limit?: number;
  };
  setHideLabel: (value: boolean) => void;
};

export function ObservationModalExtended({
  buildGetObservationsQuery,
  hasAnnotations,
  observation,
  hideLabel,
  paginationDefaults,
  setHideLabel,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { getScenarioByName } = useScenarioContext();
  const { gqlCameras } = useCameraContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  // State
  const [hideDangerZone, setHideDangerZone] = useState(true);
  const [selectedObservation, setSelectedObservation] =
    useState<ObservationProp>(observation);
  const [maxHeight, setMaxHeight] = useState<number>(0);

  useEffect(() => {
    setSelectedObservation(observation);
  }, [observation]);

  // Ref
  const annotationRef = useRef<AnnotationRef>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const [updateObservation] = useMutation(UPDATE_OBSERVATION);
  const [updateSensitiveObservation] = useMutation(UPDATE_OBSERVATION_BY_ID);
  const [addObservationBookmark] = useMutation(ADD_OBSERVATION_BOOKMARK);
  const [deleteObservationBookmark] = useMutation(DELETE_OBSERVATION_BOOKMARK);

  const selectedScenario = getScenarioByName(
    selectedObservation.customer_scenario_label.name,
  );

  const scenarioPerimeter = useMemo(() => {
    const camera = gqlCameras.find(
      (camera) => camera.id === selectedObservation.camera.id,
    );
    if (!camera) return undefined;

    const cameraScenarios = camera.cameras_scenarios.find(
      (detection) =>
        detection.customer_scenario_label.id === selectedScenario?.id,
    );

    const perimeter = isEmptyObject(cameraScenarios?.perimeter)
      ? undefined
      : cameraScenarios?.perimeter;

    return perimeter;
  }, [selectedObservation, gqlCameras, selectedScenario]);

  const hasDangerZone = (scenarioPerimeter?.position.length || 0) > 0;

  const handleExport = () => {
    if (annotationRef.current) {
      annotationRef.current.downloadImage();
    }
  };

  const handleImageDimensionsChange = useCallback(() => {
    if (imageContainerRef.current) {
      const { clientHeight } = imageContainerRef.current;
      setMaxHeight(clientHeight);
    }
  }, []);

  const [reportFalseModal, setReportFalseModal] =
    useState<ModalType>(defaultModalValue);

  const reportFalseModalValue: ModalType = {
    title: i18n.t('button.report_false'),
    body: '',
    buttonType: 'primary',
    doneText: i18n.t('button.send'),
    cancelText: i18n.t('button.cancel'),
    show: true,
  };

  const handleShowReportFalse = (
    isTrue: boolean,
    observationId?: number | null,
  ) => {
    if (isTrue && observationId) {
      updateObservation({
        variables: {
          id: observationId,
          data: { is_false_positive: true },
        },
      });
    }

    setReportFalseModal(defaultModalValue);
  };

  const handleObservationUpdate: ObservationActionEventHandler = (
    observation,
    action,
    value,
  ) => {
    if (action === 'report') {
      setReportFalseModal({
        ...reportFalseModalValue,
        observationId: observation.id,
      });
      setReportFalseModal(reportFalseModalValue);
    } else if (action === 'view_details') {
      navigate(`/observation/${observation.id}`);
    } else if (action === 'bookmark' || action === 'remove_bookmark') {
      const bookmarkId = observation.observation_users.find(
        ({ user_id }) => user?.id === user_id,
      )?.id;

      if (bookmarkId !== undefined) {
        deleteObservationBookmark({
          variables: {
            id: bookmarkId,
          },
        });
      } else {
        addObservationBookmark({
          variables: {
            data: {
              observation_id: observation.id,
              user_id: user?.id,
            },
          },
        });
      }
    } else {
      updateSensitiveObservation({
        variables: {
          id: [observation.id],
          column: action,
          value,
        },
      });
    }
  };

  const showObservationTable = paginationDefaults !== undefined;

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: `${spacing(2)}`,
          padding: `${spacing(3)} 0`,
        }}
      >
        <Button
          className="button-with-loader"
          variant={`outline-${theme}`}
          icon={WarningIcon}
          title={i18n.t('button.report_false')}
          onClick={() => {
            setReportFalseModal({
              ...reportFalseModalValue,
              observationId: selectedObservation.id,
            });
          }}
        />

        <ObservationActions
          observation={selectedObservation}
          hideReportFalsePositive
          onObservationAction={handleObservationUpdate}
        />

        <ImageDownloader
          imageUrl={observation?.imageUrl}
          onClick={hasAnnotations ? handleExport : undefined}
        />
      </div>

      <Card
        className={`border border-${theme} border-radius`}
        style={{
          overflow: 'hidden',
        }}
      >
        <Card.Body className="p-0" style={{ display: 'flex', maxHeight }}>
          {showObservationTable && (
            <CompressObservationsTable
              buildGetObservationsQuery={buildGetObservationsQuery}
              selectedObservation={selectedObservation}
              pagination={paginationDefaults}
              onObservationSelect={(selectedObservation) => {
                setSelectedObservation(selectedObservation);
              }}
              onObservationUpdate={handleObservationUpdate}
            />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'fit-content',
            }}
            ref={imageContainerRef}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <ObservationsModalImage
                ref={annotationRef}
                showLabel={!hideLabel}
                isAnnotation={hasAnnotations}
                observation={selectedObservation}
                onImageDimensionsChange={handleImageDimensionsChange}
              />
              {!hideDangerZone &&
                selectedScenario &&
                annotationRef.current?.imageRef.current && (
                  <DangerZoneOverlay
                    selectedScenario={selectedScenario}
                    scenarioPerimeter={scenarioPerimeter}
                    imageElement={annotationRef.current?.imageRef.current}
                    hideOnHover
                  />
                )}
            </div>
            {hasAnnotations && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: `${spacing(4)}`,
                  padding: `${spacing(4)}  ${spacing(3)}`,
                }}
              >
                {showObservationTable && (
                  <span style={{ marginRight: 'auto' }}>
                    <ShortCutOverlay />
                  </span>
                )}

                <CustomSwitch
                  label={`${i18n.t('modal.observation.switch.show_labels')}`}
                  checked={!hideLabel}
                  onChange={(e) => setHideLabel(!e.currentTarget.checked)}
                />
                <CustomSwitch
                  label={`${i18n.t('modal.observation.switch.danger_zone')}`}
                  checked={hasDangerZone && !hideDangerZone}
                  onChange={(e) => setHideDangerZone(!e.currentTarget.checked)}
                  disabled={!hasDangerZone}
                />
              </div>
            )}
            <ObservationDetailsRow observation={selectedObservation} />
          </div>
        </Card.Body>
      </Card>

      <ReportFalseModal
        modalValue={reportFalseModal}
        onCloseClick={(isUpdate, observationId) =>
          handleShowReportFalse(isUpdate, observationId)
        }
      />
    </>
  );
}
