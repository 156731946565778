/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as ActiveArrow } from '../../../assets/icons/active-left-arrow.svg';
import { ReactComponent as InActiveArrow } from '../../../assets/icons/inactive-left-arrow.svg';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  GRAY_MIDDLE,
  GRAY_SUBTLE,
  GRAY_WARM_DARKER_AA,
} from '../../../scss/colors';
import { Scenario } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { isString } from '../../../utils/typeUtils';
import { Button } from '../../elements/Button';

type Props = {
  scenario: Scenario;
  onClick: () => void;
  isDisabled?: boolean;
  isSelected?: boolean;
  isFirst?: boolean;
  overlayText?: string;
};

export function ScenarioButton({
  scenario,
  onClick,
  isDisabled = false,
  isSelected = false,
  isFirst = false,
  overlayText,
}: Props) {
  const { theme } = useThemeContext();
  const isOverlay = isString(overlayText);

  const loadButton = () => (
    <Button
      css={css`
        border-width: ${isFirst ? '1px' : '0'} 0 1px 0;
        width: 100%;
        justify-content: flex-start;
        height: 48px;
        border-radius: 0;
        box-shadow: none !important;
        font-weight: 500 !important;

        :hover {
          border-color: ${theme === 'light'
            ? GRAY_MIDDLE
            : GRAY_WARM_DARKER_AA};
          background: ${theme === 'light' ? GRAY_SUBTLE : GRAY_WARM_DARKER_AA};
        }

        :first-of-type {
          border-top: 0;
        }
        &.btn {
          justify-content: flex-start;
        }
      `}
      isRounded
      className="button-with-loader"
      icon={isSelected ? ActiveArrow : InActiveArrow}
      iconSpacing={3}
      variant={`outline-${theme}`}
      title={i18n.t(scenario.title)}
      disabled={isDisabled}
      onClick={onClick}
    />
  );

  if (isOverlay) {
    return (
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip
            id="button-tooltip-2"
            style={{
              display: isOverlay ? 'flex' : 'none',
            }}
          >
            {i18n.t(overlayText || '')}
          </Tooltip>
        }
      >
        <div>{loadButton()}</div>
      </OverlayTrigger>
    );
  }
  return loadButton();
}
