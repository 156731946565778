import React from 'react';
import { Button, Dropdown, FormCheck } from 'react-bootstrap';
import { Loader } from './Loader';
import { Scenario } from '../../typescript/observation/scenario';

type Props = {
  className?: string;
  containerClassName?: string;
  variant?: string;
  bodyVariant?: string;
  title?: string;
  selectAllTitle?: string;
  confirmTitle?: string;
  dropdownData: Scenario[];
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  rightIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  loading?: boolean;
  disabled?: boolean;
  selectedAll?: boolean;
  isCheckBox?: boolean;
  isConfirm?: boolean;
  onSelectedAll?: (isAllCheck: boolean, fromChild: boolean) => any | undefined;
  onClick?: (value: Scenario) => any | undefined;
  onConfirm?: (value: Scenario[]) => any | undefined;
};
export function ScenarioDropdownWithCheckbox({
  className = '',
  containerClassName = '',
  variant = '',
  bodyVariant = '',
  title = '',
  selectAllTitle = '',
  confirmTitle = '',
  dropdownData,
  loading = false,
  disabled = false,
  selectedAll = false,
  isCheckBox = false,
  isConfirm = false,
  icon: Icon = undefined,
  rightIcon: RightIcon = undefined,
  onSelectedAll = undefined,
  onClick = undefined,
  onConfirm = undefined,
}: Props) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        className={`${className} icon button-with-loader`}
        variant={variant}
        id="dropdown-basic"
        disabled={loading || disabled}
      >
        {Icon && <Icon className="me-2" />}
        {title}
        {RightIcon && <RightIcon className="ms-2" />}
        {loading && <Loader className="ms-12" />}
      </Dropdown.Toggle>

      <Dropdown.Menu
        variant={bodyVariant}
        className={`${isConfirm && 'pb-0'} ${containerClassName}`}
      >
        {isCheckBox && (
          <div className="dropdown-item border-bottom">
            <FormCheck
              className={`check-box py-1 ${dropdownData.every((item) => item.active) || 'not-check-all'}`}
              checked={selectedAll}
              label={selectAllTitle}
              onClick={(e: any) => {
                if (onSelectedAll) {
                  onSelectedAll(e.target.checked, false);
                }
              }}
            />
          </div>
        )}
        {dropdownData.map((item) =>
          isCheckBox ? (
            <div className="dropdown-item" key={item.title}>
              <FormCheck
                className="check-box"
                checked={item.active}
                label={
                  <span
                    className="scenario-list"
                    style={{
                      backgroundColor:
                        bodyVariant === 'light' ? item.colorLight : item.color,
                      color: bodyVariant === 'light' ? item.color : '#fff',
                    }}
                  >
                    {item.title}
                  </span>
                }
                onClick={() => {
                  if (onClick && onSelectedAll) {
                    onClick(item);
                    onSelectedAll(true, true);
                  }
                }}
              />
            </div>
          ) : (
            <Dropdown.Item
              className="no-hover"
              key={item.value}
              onClick={() => onClick && onClick(item)}
            >
              <span
                className="scenario-list"
                style={{
                  backgroundColor:
                    bodyVariant === 'light' ? item.colorLight : item.color,
                  color: bodyVariant === 'light' ? item.color : '#fff',
                }}
              >
                {item.title}
              </span>
            </Dropdown.Item>
          ),
        )}

        {isCheckBox && isConfirm && (
          <Button
            className="d-flex w-100 justify-content-center rounded-top-0"
            disabled={dropdownData.filter((item) => item.active).length === 0}
            onClick={() => onConfirm && onConfirm(dropdownData)}
          >
            {confirmTitle}
          </Button>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
