/* eslint-disable no-underscore-dangle */
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { CameraFilter } from './filterModal/CameraFilter';
import { DateTimeFilter } from './filterModal/DateTimeFilter';
import { PriorityFilter } from './filterModal/PriorityFilter';
import { ResponderFilter } from './filterModal/ResponderFilter';
import { ScenarioFilter } from './filterModal/ScenarioFilter';
import { StatusFilter } from './filterModal/StatusFilter';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { WhereQueryProps } from '../../graphql/queries/observation';
import {
  OBSERVATION_PRIORITIES,
  OBSERVATION_STATUSES,
  TitleAndValue,
} from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { isNumber } from '../../utils/typeUtils';
import { Button } from '../elements/Button';

type Props = {
  filters: WhereQueryProps;

  showModal: boolean;
  showCameraFilters: boolean;
  showScenarioFilters: boolean;
  hideDateFilter?: boolean;

  onApply: (obj: WhereQueryProps) => void;
  onClose: () => void;
};

export function FilterModal({
  filters,
  showModal,
  showCameraFilters,
  showScenarioFilters,
  hideDateFilter = false,
  onApply,
  onClose,
}: Props) {
  const { clientUsersList, scenariosList } = useAuthContext();
  const { theme } = useThemeContext();
  const { cameraList } = useCameraContext();

  const [activeFilters, setActiveFilters] = useState<WhereQueryProps>(filters);

  const selectedCameraList = useMemo(() => {
    const cameraIds = activeFilters.camera_id?._in || [];
    return cameraList.filter((item) => cameraIds.includes(item.sql_id));
  }, [cameraList, activeFilters.camera_id]);

  const selectedScenarioList = useMemo(() => {
    const scenarioIds = activeFilters.customer_scenario_label_id?._in || [];
    return scenariosList.map((item) => ({
      ...item,
      active: isNumber(item.id) && scenarioIds.includes(item.id),
    }));
  }, [scenariosList, activeFilters.customer_scenario_label_id]);

  const selectedStatuses = OBSERVATION_STATUSES.filter(
    (status) => status.value === activeFilters.status?._eq,
  );

  const selectedPriorities = OBSERVATION_PRIORITIES.filter(
    (priority) => priority.value === activeFilters.priority?._eq,
  );

  const showRemoveAllButton = Object.keys(activeFilters).length > 0;

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={showModal}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.filter.title')}</Modal.Title>
        <Button
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        <Row className="g-0">
          <Col md={12}>
            {!hideDateFilter &&
              activeFilters.system_timestamp !== undefined && [
                <p className="mb-1 weight-600 " key="title">
                  {i18n.t('filter.date_and_time.title')}
                </p>,
                <Button
                  key="button"
                  title={`
                  ${moment.unix(activeFilters.system_timestamp._gte).format('YYYY-MM-DD, HH:mm:ss')}
                  ~
                  ${moment.unix(activeFilters.system_timestamp._lte).format('YYYY-MM-DD, HH:mm:ss')}
                `}
                  className="button-with-loader mb-12 ph-no-capture"
                  variant={`${theme}`}
                  type="button"
                  rightIcon={CancelIcon}
                  onClick={() => {
                    const updateFilter = {
                      ...activeFilters,
                      system_timestamp: undefined,
                    };
                    setActiveFilters(updateFilter);
                  }}
                />,
              ]}

            {showCameraFilters && selectedCameraList.length !== 0 && (
              <>
                <p className="mb-1 weight-600" key="title">
                  {i18n.t('filter.camera.title')}
                </p>
                <div key="value">
                  {selectedCameraList.map((item) => (
                    <Button
                      key={item.sql_id}
                      title={item.name}
                      className="mb-2 me-2 ph-no-capture"
                      variant={`${theme}`}
                      type="button"
                      rightIcon={CancelIcon}
                      onClick={() => {
                        const updatedCameraIds = (
                          activeFilters.camera_id?._in || []
                        ).filter((id) => id !== item.sql_id);

                        const updatedFilterData = {
                          ...activeFilters,
                          camera_id:
                            updatedCameraIds.length > 0
                              ? { _in: updatedCameraIds }
                              : undefined,
                        };

                        setActiveFilters(updatedFilterData);
                      }}
                    />
                  ))}
                </div>
              </>
            )}

            {showScenarioFilters &&
              selectedScenarioList.filter((item) => item.active).length !==
                0 && (
                <>
                  <p className="mb-1 weight-600" key="title">
                    {i18n.t('filter.scenarios.title')}
                  </p>
                  <div key="value">
                    {selectedScenarioList
                      .filter((item) => item.active)
                      .map((item) => (
                        <Button
                          key={item.value}
                          title={i18n.t(item.title)}
                          className="mb-2 me-2"
                          variant={`${theme}`}
                          type="button"
                          rightIcon={CancelIcon}
                          onClick={() => {
                            const updatedScenarioIds = (
                              activeFilters.customer_scenario_label_id?._in ||
                              []
                            ).filter((id) => id !== item.id);

                            const updateFilter = {
                              ...activeFilters,
                              customer_scenario_label_id:
                                updatedScenarioIds.length > 0
                                  ? {
                                      _in: updatedScenarioIds,
                                    }
                                  : undefined,
                            };

                            setActiveFilters(updateFilter);
                          }}
                        />
                      ))}
                  </div>
                </>
              )}

            {activeFilters.responder !== undefined && [
              <p className="mb-1 weight-600 " key="title">
                {i18n.t('filter.responder.title')}
              </p>,
              <Button
                key="button"
                title={activeFilters.responder._eq}
                className="button-with-loader mb-12 ph-no-capture"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = {
                    ...activeFilters,
                    responder: undefined,
                  };
                  setActiveFilters(updateFilter);
                }}
              />,
            ]}
            {activeFilters.status !== undefined && [
              <p className="mb-1 weight-600 " key="title">
                {i18n.t('filter.status.title')}
              </p>,
              <Button
                key="button"
                title={i18n.t(selectedStatuses[0]?.title)}
                className="button-with-loader mb-12"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = { ...activeFilters, status: undefined };
                  setActiveFilters(updateFilter);
                }}
              />,
            ]}
            {activeFilters.priority !== undefined && [
              <p className="mb-1 weight-600 " key="title">
                {i18n.t('filter.priority.title')}
              </p>,
              <Button
                key="button"
                title={i18n.t(selectedPriorities[0].title)}
                className="button-with-loader mb-12"
                variant={`${theme}`}
                type="button"
                rightIcon={CancelIcon}
                onClick={() => {
                  const updateFilter = {
                    ...activeFilters,
                    priority: undefined,
                  };
                  setActiveFilters(updateFilter);
                }}
              />,
            ]}

            {showRemoveAllButton && (
              <Button
                title={i18n.t('button.remove_filters')}
                className="button-with-loader ms-auto"
                variant={`outline-${theme}`}
                type="button"
                onClick={() => {
                  setActiveFilters({});
                }}
              />
            )}
          </Col>

          {!hideDateFilter && (
            <DateTimeFilter
              onChange={(dates: any) => {
                const convertedDates = dates
                  ? dates.map((date: any) => moment(date).unix())
                  : [];
                const updateFilter = {
                  ...activeFilters,
                  system_timestamp: {
                    _gte: convertedDates[0],
                    _lte: convertedDates[1],
                  },
                };
                setActiveFilters(updateFilter);
              }}
            />
          )}

          {showCameraFilters && (
            <CameraFilter
              cameraList={cameraList}
              selectedCameraList={selectedCameraList}
              onClickSelectedCameraList={(cameras) => {
                const updateFilter = {
                  ...activeFilters,
                  camera_id:
                    cameras.length !== 0
                      ? {
                          _in: cameras.map((camera) => Number(camera.value)),
                        }
                      : undefined,
                };
                setActiveFilters(updateFilter);
              }}
            />
          )}

          {showScenarioFilters && (
            <ScenarioFilter
              selectedScenarioList={selectedScenarioList}
              onClick={(scenarios) => {
                const updateFilter = {
                  ...activeFilters,
                  customer_scenario_label_id:
                    scenarios.length !== 0
                      ? {
                          _in: scenarios
                            .filter((item) => item.active)
                            .map((scenario) => scenario.id!),
                        }
                      : undefined,
                };
                setActiveFilters(updateFilter);
              }}
            />
          )}
          <ResponderFilter
            responders={clientUsersList}
            selectedResponder={activeFilters.responder?._eq}
            onClick={(item: string) => {
              const updateFilter = { ...activeFilters };
              updateFilter.responder = { _eq: item };
              setActiveFilters(updateFilter);
            }}
          />
          <StatusFilter
            statusOfObservations={selectedStatuses[0]}
            onClick={(item: TitleAndValue) => {
              const updateFilter = { ...activeFilters };
              updateFilter.status = { _eq: String(item.value) };
              setActiveFilters(updateFilter);
            }}
          />
          <PriorityFilter
            selectedPriority={selectedPriorities[0]}
            onClick={(item: TitleAndValue) => {
              const updateFilter = { ...activeFilters };
              updateFilter.priority = { _eq: String(item.value) };
              setActiveFilters(updateFilter);
            }}
          />
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          title={i18n.t('button.cancel')}
          className="button-with-loader"
          variant={`outline-${theme}`}
          type="button"
          onClick={onClose}
        />
        <Button
          title={i18n.t('button.confirm')}
          className="button-with-loader"
          variant="primary"
          type="button"
          onClick={() => {
            onApply(activeFilters);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
