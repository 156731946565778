/* eslint-disable unused-imports/no-unused-vars */
import React, { FC, useState } from 'react';
import { Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { imageUpdate } from '../../../apis/api-request';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import i18n from '../../../utils/i18n';
import { Button } from '../../elements/Button';

interface Props {
  onCloseClick: () => void;
  hideParentModal: () => void;
}

const ResetBackgroundImage: FC<Props> = function ResetBackgroundImage({
  onCloseClick,
  hideParentModal,
}) {
  const { userCookie, onTokenSave } = useAuthContext();
  const { camera } = useCameraContext();
  const { theme } = useThemeContext();

  const [loading, setLoading] = useState<boolean>(false);

  const onUpdateImageCamera = async () => {
    if (!camera) return false;

    setLoading(true);
    const param = { id: camera.id, scenario: 'road-safety-eq' };
    const updateCameraImage = await imageUpdate(userCookie.userToken, param);
    setLoading(false);

    if (updateCameraImage.status === 200) {
      toast.success(i18n.t('toast.success.site_is_verified'));
      return true;
    }
    if (updateCameraImage.status === 401) {
      onTokenSave('');
    }
    toast.error(updateCameraImage.data.message);
    return false;
  };

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">
        {i18n.t('camera.reset_background_image.title')}
      </p>
      <p className="sub-color">
        {i18n.t('camera.reset_background_image.body')}
      </p>

      <Button
        variant={`outline-${theme}`}
        type="button"
        className="button-with-loader"
        title={i18n.t('button.site_ok')}
        loading={loading}
        disabled={loading}
        onClick={() => {
          onUpdateImageCamera();
        }}
      />
      <hr />
    </Col>
  );
};

export default ResetBackgroundImage;
