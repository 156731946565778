import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';
import { DangerZoneSettings } from '../cameras/settings/DangerZoneSettings';
import RemoveCamera from '../cameras/settings/RemoveCamera';
import ResetBackgroundImage from '../cameras/settings/ResetBackgroundImage';
import { ResetCamera } from '../cameras/settings/ResetCamera';
import { ScenarioScheduleSettings } from '../cameras/settings/ScenarioScheduleSettings';
import { ScenarioSettings } from '../cameras/settings/ScenarioSettings';
import { ScheduleSettings } from '../cameras/settings/ScheduleSettings';
import { Button } from '../elements/Button';
import { Loader } from '../elements/Loader';

interface Props {
  cameraId: number;
  show: boolean;
  onCloseClick: () => void;
}

export function CameraSettingsModal({ cameraId, show, onCloseClick }: Props) {
  const { user } = useAuthContext();
  const { isCameraListLoading, setActiveCameraId } = useCameraContext();
  const { theme } = useThemeContext();
  const [hideModal, setHideModal] = useState(false);

  useEffect(() => {
    setActiveCameraId(cameraId);
  }, [cameraId, setActiveCameraId]);

  const onHideModal = useCallback(() => {
    setHideModal(!hideModal);
  }, [hideModal]);

  return (
    <Modal
      centered
      contentClassName={theme}
      className={`${hideModal && 'd-none'} custom-modal`}
      show={show}
      onHide={onCloseClick}
    >
      <Modal.Header>
        <Modal.Title>{i18n.t('modal.camera.setting.title')}</Modal.Title>
        <Button
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={onCloseClick}
        />
      </Modal.Header>
      <Modal.Body className="d-flex flex-column justify-content-center">
        {isCameraListLoading ? (
          <Loader main className="mx-auto" />
        ) : (
          <Row>
            <ScenarioSettings hideParentModal={onHideModal} />
            <DangerZoneSettings hideParentModal={onHideModal} />
            <ScheduleSettings hideParentModal={onHideModal} />
            <ScenarioScheduleSettings hideParentModal={onHideModal} />

            {user?.customer.feature_flags.cameras?.set_site_button && (
              <ResetBackgroundImage
                hideParentModal={onHideModal}
                onCloseClick={onCloseClick}
              />
            )}

            {user?.customer.feature_flags.cameras?.init_site && (
              <ResetCamera hideParentModal={onHideModal} />
            )}
            <RemoveCamera
              hideParentModal={onHideModal}
              onCloseClick={onCloseClick}
            />
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}
