/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ObservationModalDefault } from './observation/ObservationModalDefault';
import { ObservationModalExtended } from './observation/ObservationModalExtended';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import {
  GetObservationsNoOpQueryBuilder,
  GetObservationsQueryBuilder,
} from '../../hooks/graphql/observations';
import { spacing } from '../../scss/spacing';
import { ObservationProp } from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { Button } from '../elements/Button';
import { CustomSwitch } from '../elements/CustomSwitch';
import { Loader } from '../elements/Loader';
import { SectionTitle } from '../elements/SectionTitle';

interface Props {
  variant: 'default' | 'extended';
  buildGetObservationsQuery?: GetObservationsQueryBuilder;
  observation?: ObservationProp;
  showModal: boolean;
  paginationDefaults?: {
    pageNumber?: number;
    limit?: number;
  };
  showShortcutInfo?: boolean;
  isLoading?: boolean;
  onNextPress?: () => void;
  onBackPress?: () => void;
  onFKeyPress?: () => void;
  onAKeyPress?: () => void;
  onRKeyPress?: () => void;
  onCloseClick: () => void;
}

export function ObservationModal({
  variant,
  showModal,
  observation,
  isLoading,
  showShortcutInfo,
  paginationDefaults,
  buildGetObservationsQuery = GetObservationsNoOpQueryBuilder,
  onCloseClick,
  onNextPress,
  onBackPress,
  onFKeyPress,
  onAKeyPress,
  onRKeyPress,
}: Props) {
  // Context
  const { theme } = useThemeContext();
  const { featureFlags } = useAuthContext();

  // State
  const [showLabel, setShowLabel] = useState<boolean>(
    featureFlags.observations.show_labels_by_default || false,
  );

  useEffect(() => {
    if (variant === 'default') {
      const keyDownHandler = (event: KeyboardEvent) => {
        if (event.key === 'ArrowRight') {
          event.preventDefault();
          onNextPress?.();
        }
        if (event.key === 'ArrowLeft') {
          event.preventDefault();
          onBackPress?.();
        }
        if (event.shiftKey && event.key === 'F') {
          event.preventDefault();
          onFKeyPress?.();
        }
        if (event.shiftKey && event.key === 'A') {
          event.preventDefault();
          onAKeyPress?.();
        }
        if (event.shiftKey && event.key === 'R') {
          event.preventDefault();
          onRKeyPress?.();
        }
      };

      document.addEventListener('keydown', keyDownHandler);

      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }
    return () => false;
  }, [
    variant,
    onNextPress,
    onBackPress,
    onFKeyPress,
    onAKeyPress,
    onRKeyPress,
  ]);

  if (!showModal || !observation) {
    return null;
  }

  const showAnnotation = featureFlags.observations.show_annotation;
  const hasAnnotation =
    (showAnnotation &&
      observation &&
      observation.id >= Number(showAnnotation)) ||
    false;

  return (
    <Modal
      centered
      contentClassName={theme}
      className="danger-zone-modal"
      show={showModal}
      onHide={() => onCloseClick()}
      css={css`
        .modal-dialog {
          max-width: ${variant === 'default' ? '70%' : '90%'};
          max-height: calc(100% - var(--bs-modal-margin) * 2);
          width: fit-content;

          .modal-content {
            min-height: auto !important;

            .modal-header {
              padding-top: ${spacing(4)};
            }

            .modal-body {
              padding-bottom: ${spacing(6)};
            }
          }
        }
      `}
    >
      <Modal.Header>
        {variant === 'default' ? (
          <div className="d-flex">
            <span className={`me-12 ${!showShortcutInfo && 'd-none'}`}>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {i18n.t('show_observation_modal.hint')}
                  </Tooltip>
                }
              >
                <InfoIcon className="icon" />
              </OverlayTrigger>
            </span>
            {hasAnnotation && (
              <CustomSwitch
                label={`${i18n.t('modal.observation.switch.show_labels')}`}
                checked={showLabel}
                onChange={(e) => setShowLabel(e.currentTarget.checked)}
              />
            )}
          </div>
        ) : (
          <SectionTitle
            title={`${i18n.t('modal.observation.title')}`}
            removeMargins
          />
        )}

        <Button
          icon={CancelIcon}
          variant="transparent"
          className="border-0 ms-auto"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div
          className={`position-absolute bottom-50 ${!isLoading && 'd-none'} ${theme}`}
          style={{
            left: '48%',
            zIndex: 1,
            padding: 10,
            borderRadius: '50%',
          }}
        >
          <Loader main />
        </div>

        {variant === 'extended' && (
          <ObservationModalExtended
            observation={observation}
            buildGetObservationsQuery={buildGetObservationsQuery}
            hasAnnotations={hasAnnotation}
            paginationDefaults={paginationDefaults}
            hideLabel={!showLabel}
            setHideLabel={(value) => setShowLabel(!value)}
          />
        )}

        {variant === 'default' && (
          <ObservationModalDefault
            hideLabel={showLabel}
            isAnnotation={hasAnnotation}
            observation={observation}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
