import React from 'react';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import { useTimeRangeContext } from '../../contextapi/TimeRangeProvider';
import { DaysListProps } from '../../typescript/grouping/grouping-observation';
import i18n from '../../utils/i18n';
import { CustomDropdown, DropdownProps } from '../elements/CustomDropdown';

type Props = {
  className?: string;
  onChange?: (timeRange: DaysListProps) => void;
};

export function TimeRangeDropdown({ className, onChange }: Props) {
  const { timeRange, setTimeRange, timeRangesData, updateTimeRangeQuery } =
    useTimeRangeContext();

  return (
    <CustomDropdown
      className={className}
      title={`${i18n.t(timeRange.title)}`}
      dropdownData={timeRangesData}
      icon={CalendarIcon}
      onClick={(selectedItem: DropdownProps) => {
        const selected = { ...selectedItem } as DaysListProps; // TODO: Fix this type casting

        if (selected.isDatePicker) {
          const dateArray = String(selected.value)
            .split(',')
            .map((item) => Number(item));
          selected.value = Number(dateArray[0]);
          selected.end = Number(dateArray[1]);
        }

        setTimeRange(selected);
        updateTimeRangeQuery(selected);
        if (onChange) {
          onChange(selected);
        }
      }}
    />
  );
}
