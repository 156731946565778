import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadingPage } from './LoadingPage';
import { ReactComponent as CameraIcon } from '../assets/dashboard/camera.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings-dark.svg';
import { CameraStatus } from '../components/camera/CameraStatus';
import { ExtendedCameraHeatMap } from '../components/camera/variants/ExtendedCameraHeatMap';
import { ObservationCharts } from '../components/charts-container/ObservationCharts';
import { Breadcrumb } from '../components/elements/Breadcrumb';
import { Button } from '../components/elements/Button';
import { FlexCol } from '../components/elements/FlexCol';
import { ImageDownloader } from '../components/elements/ImageDownloader';
import { PageTitle } from '../components/elements/PageTitle';
import { SectionTitle } from '../components/elements/SectionTitle';
import { CameraSettingsModal } from '../components/modal/CameraSettingsModal';
import { ObservationList } from '../components/observation/ObservationList';
import { TimeRangeDropdown } from '../components/shared/TimeRangeDropdown';
import { useCameraContext } from '../contextapi/CameraProvider';
import { useThemeContext } from '../contextapi/ThemeProvider';
import { useTimeRangeContext } from '../contextapi/TimeRangeProvider';
import { GRAY_WARM_DARKER_PLACEHOLDER } from '../scss/colors';
import { spacing } from '../scss/spacing';
import { TIME_RANGES } from '../typescript/grouping/grouping-observation';
import { Scenario } from '../typescript/observation/scenario';
import i18n from '../utils/i18n';

export function CameraDetailsPage() {
  const { theme } = useThemeContext();
  const { gqlCamera, setActiveCameraId, exportCameraImage } =
    useCameraContext();

  const { cameraId: cameraIdParam } = useParams();
  if (!cameraIdParam) {
    throw new Error('Camera ID is required');
  }
  const cameraId = Number(cameraIdParam);

  const { timeRange, setTimeRange, shouldSetDefaultTimeRange } =
    useTimeRangeContext();

  useEffect(() => {
    if (shouldSetDefaultTimeRange) {
      setTimeRange(TIME_RANGES[1]);
    }
  }, [shouldSetDefaultTimeRange, setTimeRange]);

  useEffect(() => {
    setActiveCameraId(cameraId);
  }, [cameraId, setActiveCameraId]);

  const [searchParams, setSearchParams] = useSearchParams();
  const scenarioIdParam = searchParams.get('scenarioId');

  const [showCameraSettingsModal, setShowCameraSettingsModal] =
    useState<boolean>(false);

  const scenarioId = scenarioIdParam ? Number(scenarioIdParam) : undefined;

  const timePeriod = useMemo(
    () => ({
      from: timeRange.value,
      until: timeRange.end,
    }),
    [timeRange],
  );

  const handleScenarioChange = (scenario: Scenario) => {
    setSearchParams((searchParams) => {
      if (scenario) {
        searchParams.set('scenarioId', String(scenario.id));
      } else if (searchParams.has('scenarioId')) {
        searchParams.delete('scenarioId');
      }

      return searchParams;
    });
  };

  if (!gqlCamera || gqlCamera.id !== cameraId) {
    return <LoadingPage />;
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Breadcrumb
            title={`${i18n.t('page.camera_details.go_back')}`}
            toUrl="/cameras"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12} className="d-flex justify-content-between mb-32">
          <div className="d-flex flex-column gap-2">
            <PageTitle
              title={i18n.t('page.camera_details.title')}
              removeMargins
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: spacing(4),
              }}
            >
              <span
                style={{
                  display: 'flex',
                  gap: spacing(2),
                  color: GRAY_WARM_DARKER_PLACEHOLDER,
                  fontWeight: 500,
                }}
              >
                <CameraIcon />
                {gqlCamera.name}
              </span>
              {gqlCamera.status !== null && (
                <CameraStatus status={gqlCamera.status} />
              )}
            </div>
          </div>
          <div className="d-flex gap-2 align-items-end">
            <Button
              className="button-with-loader"
              icon={SettingsIcon}
              variant={`outline-${theme}`}
              title={i18n.t('page.camera_details.settings')}
              onClick={() =>
                setShowCameraSettingsModal(!showCameraSettingsModal)
              }
            />
            <TimeRangeDropdown />
          </div>
        </Col>
      </Row>
      <Row className="mb-32">
        <FlexCol md={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: spacing(6),
            }}
          >
            <SectionTitle
              removeMargins
              title={i18n.t('page.camera_details.heatmap')}
            />
            <ImageDownloader onClick={exportCameraImage} />
          </div>
          <ExtendedCameraHeatMap
            camera={gqlCamera}
            scenarioId={scenarioId}
            onScenarioChange={handleScenarioChange}
          />
        </FlexCol>
      </Row>
      <Row>
        <SectionTitle title={i18n.t('page.camera_details.stats')} />
        <ObservationCharts cameraIds={[gqlCamera.id]} />
      </Row>
      <Row>
        <FlexCol md={12}>
          <SectionTitle
            title={i18n.t('page.camera_details.observation_list')}
          />
          <ObservationList
            variant="camera"
            cameraId={cameraId}
            timePeriod={timePeriod}
          />
        </FlexCol>
      </Row>
      <CameraSettingsModal
        cameraId={gqlCamera.id}
        show={showCameraSettingsModal}
        onCloseClick={() => {
          setShowCameraSettingsModal(!showCameraSettingsModal);
        }}
      />
    </>
  );
}
