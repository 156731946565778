import i18n from 'i18next';
import moment from 'moment';
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Cookies } from 'react-cookie';
import { initReactI18next } from 'react-i18next';
import { z } from 'zod';
import { fetchTranslationCatalog } from '../apis/traduora-api';
import { LoadingPage } from '../pages/LoadingPage';

const supportedLanguages = ['en', 'sv_SE', 'es'] as const;
type SupportedLanguage = (typeof supportedLanguages)[number];
const fallbackLanguage = 'en' satisfies SupportedLanguage;

type TranslationContextType = {
  language: SupportedLanguage;
  setLanguage: (locale: SupportedLanguage) => void;
};

export const TranslationContext = createContext<TranslationContextType | null>(
  null,
);

export const useTranslationContext = () => {
  const context = React.useContext(TranslationContext);
  if (!context) {
    throw new Error(
      'useTranslationContext must be used within a TranslationProvider',
    );
  }
  return context;
};

export const TranslationProvider = ({ children }: PropsWithChildren) => {
  const storedLanguage =
    localStorage.getItem('language') || new Cookies().get('language');
  const defaultLanguage =
    z.enum(supportedLanguages).safeParse(storedLanguage).data ||
    fallbackLanguage;

  const [language, setLanguage] = useState<SupportedLanguage>(defaultLanguage);
  const [isLoading, setIsLoading] = useState(true);

  const changeLanguage = useCallback(async (language: SupportedLanguage) => {
    try {
      if (!i18n.hasResourceBundle(language, 'translation')) {
        const catalog = await fetchTranslationCatalog(language);
        i18n.addResourceBundle(language, 'translation', catalog);
      }

      i18n.changeLanguage(language);
      moment.locale(language === 'en' ? `${language}-gb` : language);
    } catch (error) {
      console.error('Error changing language:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!i18n.isInitialized) {
      i18n.use(initReactI18next).init({
        resources: {},
        lng: language,
        fallbackLng: fallbackLanguage,

        interpolation: {
          escapeValue: false,
        },
      });
    }

    changeLanguage(language);
  }, [language, changeLanguage]);

  const context: TranslationContextType = useMemo(
    () => ({
      language,
      setLanguage: (locale) => {
        if (language === locale) return;

        localStorage.setItem('language', locale);
        new Cookies().remove('language');

        setIsLoading(true);
        setLanguage(locale);
      },
    }),
    [language],
  );

  return isLoading ? (
    <LoadingPage />
  ) : (
    <TranslationContext.Provider value={context}>
      {children}
    </TranslationContext.Provider>
  );
};
