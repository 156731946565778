import mixpanel, { Dict } from 'mixpanel-browser';

let isInitialized = false;

export const initMixpanel = () => {
  const mixpanelToken = process.env.REACT_APP_MIXPANEL;

  if (mixpanelToken) {
    mixpanel.init(mixpanelToken, {
      debug: process.env.NODE_ENV === 'development',
      api_host: 'https://api-eu.mixpanel.com',
      opt_out_tracking_by_default: true,
    });

    isInitialized = true;
  }
};

export const Mixpanel = {
  identify: (id?: string) => {
    if (!isInitialized) return;

    mixpanel.identify(id);
  },
  track: (eventName: string, properties: Dict) => {
    if (!isInitialized) return;

    mixpanel.track(eventName, properties);
  },
  people: {
    set: (prop: Dict) => {
      if (!isInitialized) return;

      mixpanel.people.set(prop);
    },
  },
  reset: () => {
    if (!isInitialized) return;

    mixpanel.reset();
  },
  optInTracking: () => {
    if (!isInitialized) return;

    mixpanel.opt_in_tracking();
  },
  optOutTracking: () => {
    if (!isInitialized) return;

    mixpanel.opt_out_tracking();
  },
};
