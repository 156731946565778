import React, { useEffect, useState } from 'react';
import { Col, FormCheck, Modal, Row } from 'react-bootstrap';
import ScenarioSchedule from './scenario-schedule/ScenarioSchedule';
import { ReactComponent as CancelIcon } from '../../../assets/icons/cancel.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { getCameraScenarios } from '../../../lib/helpers/scenario';
import { ScenarioProps } from '../../../typescript/observation/scenario';
import i18n from '../../../utils/i18n';
import { Button } from '../../elements/Button';

type ScheduleProps = {
  [key: string]: string[];
};
type ScenarioScheduleProps = {
  [key: string]: ScheduleProps;
};
interface Props {
  hideParentModal: () => void;
}

const daysArray: ScheduleProps = {
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: [],
};

export function ScenarioScheduleSettings({ hideParentModal }: Props) {
  const { theme } = useThemeContext();
  const { camera, gqlCamera, updateCamera } = useCameraContext();
  const { userScenarios } = useScenarioContext();

  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [asCameraScheduleArray, setAsCameraScheduleArray] = useState([
    { value: 'radiobox.as_camera_schedule', isChecked: false },
    { value: 'radiobox.custom', isChecked: true },
  ]);
  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioProps | null>(null);
  const [schedulingValue, setSchedulingValue] =
    useState<ScenarioScheduleProps | null>(null);
  const [daysArrayValue, setDaysArrayValue] =
    useState<ScheduleProps>(daysArray);

  const activeScenarios = getCameraScenarios(userScenarios, gqlCamera);

  const updateAsCameraScheule = (scenario: ScenarioProps) => {
    const updatedDays = [...asCameraScheduleArray];
    const { scenarioSchedule, cameraSchedule } = camera
      ? JSON.parse(camera?.schedule)
      : { scenarioSchedule: null, cameraSchedule: null };
    if (cameraSchedule) {
      if (
        JSON.stringify(cameraSchedule) ===
        JSON.stringify(scenarioSchedule[scenario.value])
      ) {
        updatedDays[0].isChecked = true;
        updatedDays[1].isChecked = false;
      } else {
        updatedDays[0].isChecked = false;
        updatedDays[1].isChecked = true;
      }
      setAsCameraScheduleArray(updatedDays);
    }
  };

  const onScenarioClick = (onSelectedScenario: ScenarioProps) => {
    setSelectedScenario(onSelectedScenario);
    updateAsCameraScheule(onSelectedScenario);
  };

  const handleClose = () => {
    hideParentModal();
    setShow(false);
  };
  const handleShow = () => {
    hideParentModal();
    setShow(true);
  };

  function updateCameraSchedule() {
    if (camera) {
      if (camera.scenarios.length !== 0) {
        const scenario = activeScenarios[0];
        if (camera.schedule) {
          const { scenarioSchedule } = JSON.parse(camera.schedule);
          setSchedulingValue(scenarioSchedule);
        }
        setSelectedScenario(scenario);
        updateAsCameraScheule(scenario);
      }
    }
  }

  const onCameraUpdateSchedule = async () => {
    if (camera) {
      const schedule = JSON.parse(camera.schedule);
      schedule.scenarioSchedule = schedulingValue;
      camera.schedule = JSON.stringify(schedule);

      setLoading(true);
      await updateCamera(camera);
      setLoading(false);
    }

    handleClose();
  };

  useEffect(() => {
    updateCameraSchedule();
  }, [camera]);

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">
        {`${i18n.t('camera.scenarios_schedule.title')}`}
      </p>
      <p className="sub-color">{i18n.t('camera.scenarios_schedule.body')}</p>

      <Button
        icon={AddIcon}
        variant={`outline-${theme}`}
        type="button"
        title={i18n.t('button.add_schedule')}
        onClick={handleShow}
      />

      <Modal
        centered
        contentClassName={theme}
        className="custom-modal schedule-modal"
        show={show}
        onHide={() => handleClose()}
      >
        <Modal.Header>
          <Modal.Title>
            {i18n.t('modal.add_scenario_schedule.title')}
          </Modal.Title>
          <Button
            icon={CancelIcon}
            variant="transparent"
            className="border-0"
            type="button"
            onClick={() => handleClose()}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            {!selectedScenario && (
              <Col md={12}>{i18n.t('modal.add_scenario_schedule.empty')}</Col>
            )}
            {selectedScenario && (
              <>
                <Col md={12}>
                  {asCameraScheduleArray.map((item, index) => (
                    <FormCheck
                      checked={item.isChecked}
                      key={`${item.value}`}
                      label={i18n.t(item.value)}
                      className="weight-600"
                      name="ScheduleGroup"
                      type="radio"
                      onClick={() => {
                        if (index === 0) {
                          const { cameraSchedule } = JSON.parse(
                            camera.schedule,
                          );
                          const scheduled = cameraSchedule as ScheduleProps;
                          const udpatedSchedule = { ...schedulingValue };
                          udpatedSchedule[selectedScenario!.value] = scheduled;
                          setSchedulingValue(udpatedSchedule);
                        }
                        const updatedItems = [...asCameraScheduleArray];
                        updatedItems[index] = {
                          ...updatedItems[index],
                          isChecked: true,
                        };
                        updatedItems.forEach((update, i) => {
                          if (i !== index) {
                            const updatedItem = { ...update };
                            updatedItem.isChecked = false;
                            updatedItems[i] = updatedItem;
                          }
                        });
                        setAsCameraScheduleArray(updatedItems);
                      }}
                    />
                  ))}
                </Col>

                {asCameraScheduleArray[1].isChecked && selectedScenario && (
                  <ScenarioSchedule
                    scenario={selectedScenario}
                    daysArrayValue={daysArrayValue}
                    onScenarioSchedule={(schedule) => {
                      const udpatedSchedule = { ...schedulingValue };
                      udpatedSchedule[selectedScenario.value] = schedule;
                      setSchedulingValue(udpatedSchedule);
                      setDaysArrayValue(schedule);
                    }}
                  />
                )}
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {selectedScenario && (
            <>
              <div className="action-container">
                <div className="scenario-tag">
                  <ul>
                    {activeScenarios.map((scenario) => (
                      <li
                        className="add-cursor scenario-list ms-12 mb-12"
                        key={scenario.value}
                        aria-hidden
                        style={{
                          backgroundColor:
                            theme === 'light'
                              ? scenario.colorLight
                              : scenario.color,
                          color: theme === 'light' ? scenario.color : '#fff',
                          opacity:
                            selectedScenario?.value === scenario.value
                              ? 1.0
                              : 0.5,
                        }}
                        onClick={() => onScenarioClick(scenario)}
                      >
                        {i18n.t(scenario.title)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <Button
                title={i18n.t('button.back_to_settings')}
                className="button-with-loader"
                variant={`outline-${theme}`}
                type="button"
                onClick={() => handleClose()}
              />

              <Button
                title={i18n.t('button.save_changes')}
                className="button-with-loader"
                variant="primary"
                type="button"
                onClick={onCameraUpdateSchedule}
                loading={loading}
                disabled={loading}
              />
            </>
          )}
        </Modal.Footer>
      </Modal>

      <hr />
    </Col>
  );
}
