import { ButtonVariant } from '../../components/elements/Button';

export type ModalType = {
  title: string;
  body: string;
  buttonType: ButtonVariant;
  doneText: string;
  cancelText: string;
  show: boolean;
  observationId?: number | null;
  loading?: boolean;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const defaultModalValue: ModalType = {
  title: '',
  body: '',
  buttonType: 'primary',
  doneText: '',
  cancelText: '',
  show: false,
  icon: undefined,
};
