import { useMutation } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ObservationsTable } from './ObservationsTable';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useScenarioContext } from '../../contextapi/ScenarioProvider';
import {
  DELETE_OBSERVATIONS,
  UPDATE_OBSERVATIONS,
} from '../../graphql/mutations';
import { ObservationsSetInput } from '../../graphql/mutations/groups';
import { UPDATE_OBSERVATION_BY_ID } from '../../graphql/mutations/observation';
import { WhereQueryProps } from '../../graphql/queries/observation';
import {
  GetGroupObservationQueryVariables,
  GetObservationsQueryBuilder,
  GetObservationsQueryVariables,
  isGetAlertObservationsQueryResponse,
  isGetBookmarkedObservationsQueryResponse,
  isGetGroupObservationQueryResponse,
  ObservationsListVariant,
  useGetObservationsQuery,
} from '../../hooks/graphql/observations';
import { TimePeriod } from '../../typescript/datetime';
import { ObservationProp } from '../../typescript/observation/observation';
import { convertToScenarioName } from '../../typescript/observation/scenario';
import i18n from '../../utils/i18n';
import { isDefined } from '../../utils/typeUtils';
import { Loader } from '../elements/Loader';

type Props = {
  variant: ObservationsListVariant;
  alertId?: number;
  cameraId?: number;
  groupId?: number;
  cameraIds?: number[];
  assignee?: string;
  timePeriod?: TimePeriod;
};

export function ObservationList({
  variant,
  alertId,
  cameraId,
  groupId,
  cameraIds,
  assignee,
  timePeriod,
}: Props) {
  const { onTokenSave } = useAuthContext();
  const { getScenariosByName } = useScenarioContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get('pageNo')
    ? Number(searchParams.get('pageNo'))
    : 1;
  const limitParam = searchParams.get('limit')
    ? Number(searchParams.get('limit'))
    : null;
  const filterParam: string = searchParams.get('filter')
    ? decodeURI(String(searchParams.get('filter')))
    : '{}';
  const filter: WhereQueryProps = JSON.parse(filterParam);

  const [limit, setLimit] = useState(limitParam || 10);
  const [queryVar, setQueryVar] = useState<WhereQueryProps>(filter);
  const [observations, setObservations] = useState<Array<ObservationProp>>([]);
  const [totalPages, setTotalPage] = useState(0);
  const [isOrder, setIsOrder] = useState(true);

  const offset = (pageNumber - 1) * limit;

  if (variant === 'group' && !isDefined(timePeriod)) {
    throw new Error('Time period is required for group variant');
  }

  const buildGetObservationsQuery = useCallback<GetObservationsQueryBuilder>(
    ({ limit, offset, isOrder }) => {
      const queryVariables =
        variant !== 'group'
          ? ({
              ...(alertId && { id: alertId }),
              limit,
              offset,
              where: {
                is_false_positive: { _eq: false },
                ...(assignee && { responder: { _eq: assignee } }),
                ...(cameraId && { camera_id: { _eq: cameraId } }),
                ...(timePeriod && {
                  system_timestamp: {
                    _gte: timePeriod.from,
                    _lte: timePeriod.until,
                  },
                }),
                ...queryVar,
              },
              orderBy: !isOrder ? 'asc' : 'desc',
            } satisfies GetObservationsQueryVariables)
          : ({
              from: timePeriod!.from,
              until: timePeriod!.until,
              group_id: isDefined(groupId) ? String(groupId) : undefined,
              camera_ids: cameraIds,
              camera_ids_string: cameraIds
                ? `{${cameraIds.join(', ')}}`
                : undefined,
              limit,
              offset,
              where: {
                is_false_positive: { _eq: false },
                ...queryVar,
              },
              order_by: { created: !isOrder ? 'asc' : 'desc' },
            } satisfies GetGroupObservationQueryVariables);

      return [variant, queryVariables];
    },
    [
      variant,
      alertId,
      assignee,
      cameraId,
      timePeriod,
      groupId,
      cameraIds,
      queryVar,
    ],
  );

  const [_, queryVariables] = buildGetObservationsQuery({
    limit,
    offset,
    isOrder,
  });

  const { loading, error, data, refetch } = useGetObservationsQuery(
    variant,
    queryVariables,
  );

  useEffect(() => {
    refetch();
  }, [timePeriod, refetch]);

  const [updateSensitiveObservation] = useMutation(UPDATE_OBSERVATION_BY_ID);
  const [updateObservation] = useMutation(UPDATE_OBSERVATIONS);
  const [deleteObservation, deleteActions] = useMutation(DELETE_OBSERVATIONS);

  const onDeleteObservation = (observationIds: Array<number>) => {
    deleteObservation({
      variables: {
        observationIds,
      },
    });
  };

  const onUpdateObservation = (
    observationIds: Array<number>,
    value: ObservationsSetInput,
  ) => {
    updateObservation({
      variables: {
        observationIds,
        data: value,
      },
    });
  };

  useEffect(() => {
    if (data) {
      if (isGetAlertObservationsQueryResponse(data)) {
        const observations = data.alert.alert_incident_observations.map(
          (item) => item.observation,
        );
        setObservations(observations);
        setTotalPage(
          Math.ceil(
            data.alert.alert_incident_observations_aggregate.aggregate.count /
              limit,
          ),
        );
      } else if (isGetBookmarkedObservationsQueryResponse(data)) {
        const observations = data.observation_user.map(
          (item) => item.observation,
        );
        setObservations(observations);
        setTotalPage(
          Math.ceil(data.observation_user_aggregate.aggregate.count / limit),
        );
      } else if (isGetGroupObservationQueryResponse(data)) {
        const observations = data.fetch_observations_by_group_id;
        setObservations(observations);
        setTotalPage(
          Math.ceil(
            data.fetch_observations_by_group_id_aggregate.aggregate.count /
              limit,
          ),
        );
      } else {
        setObservations(data.observations);
        setTotalPage(
          Math.ceil(data.observations_aggregate.aggregate.count / limit),
        );
      }
    } else {
      setObservations([]);
    }
  }, [data, refetch, searchParams, variant, limit]);

  const scenarioIds = useMemo(() => {
    if (data && isGetGroupObservationQueryResponse(data)) {
      const groupItem = data?.observation_group_statistics.at(0);
      if (groupItem) {
        const convertedScenarios = groupItem.scenario_names.map(
          convertToScenarioName,
        );
        return getScenariosByName(convertedScenarios).map(
          (scenario) => scenario.id,
        );
      }
    }

    return undefined;
  }, [data, getScenariosByName]);

  useEffect(() => {
    if (deleteActions.data) {
      toast.success(i18n.t('toast.success.deleted'), {
        autoClose: 500,
        onClose: () => window.location.reload(),
      });
    }
  }, [deleteActions.data]);

  useEffect(() => {
    if (error?.message === 'Authentication hook unauthorized this request') {
      onTokenSave('');
      refetch();
    }
  }, [error, onTokenSave, refetch]);

  const onSensitiveObservation = (
    observationIds: Array<number>,
    column: string,
    value: string,
  ) => {
    updateSensitiveObservation({
      variables: {
        id: observationIds,
        column,
        value,
      },
    });
  };

  const onFilterApplied = (
    whereQuery: WhereQueryProps,
    listLimit: number,
    pageNo: number,
  ) => {
    setQueryVar(whereQuery);
    setLimit(listLimit);
    setSearchParams((searchParams) => {
      searchParams.set('pageNo', String(pageNo));
      searchParams.set('limit', String(listLimit));
      searchParams.set('filter', JSON.stringify(whereQuery));

      return searchParams;
    });
  };

  if (loading || error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  return (
    <ObservationsTable
      variant={variant}
      buildGetObservationsQuery={buildGetObservationsQuery}
      observations={observations}
      filter={filter}
      limit={limit}
      pageNumber={pageNumber}
      totalPages={totalPages}
      loading={loading}
      assignee={assignee}
      isOrder={isOrder}
      setIsOrder={setIsOrder}
      showCameraFilters={variant !== 'camera' && variant !== 'group'}
      showScenarioFilters={variant !== 'group'}
      hideCameraColumn={variant === 'camera'}
      hideDateFilter={variant !== 'group' && isDefined(timePeriod)}
      onSensitiveObservation={onSensitiveObservation}
      onFilterApplied={onFilterApplied}
      onUpdateObservation={onUpdateObservation}
      onDeleteObservation={onDeleteObservation}
      selectedScenarioIds={scenarioIds}
      selectedCameraIds={cameraIds || []}
      timePeriod={timePeriod}
    />
  );
}
