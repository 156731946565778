import Konva from 'konva';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { CanvasRef, DangerZone } from './DangerZone';
import { useCameraContext } from '../../../../contextapi/CameraProvider';
import {
  ScenarioPerimeter,
  XYCoordinate,
} from '../../../../hooks/graphql/camera';
import { DangerZoneResult } from '../../../../typescript/camera/camera';
import { Scenario } from '../../../../typescript/observation/scenario';
import { Nullable } from '../../../../utils/typeUtils';

export type CanvasSize = {
  width: number;
  height: number;
};

interface Props {
  selectedScenario: Scenario;
  scenarioPerimeter?: ScenarioPerimeter;
  selectedZoneId?: number;
  imageElement: Nullable<HTMLImageElement>;
  editable?: boolean;
  hideOnHover?: boolean;
  onSelectedZoneUpdated?: (id: number, coordinates: XYCoordinate[]) => void;
  onZoneAdded?: (coordinates: XYCoordinate[]) => void;
  onZoneSelected?: (id: number) => void;
  onClear?: () => void;
}

const convertToDangerZone = (
  perimeter: ScenarioPerimeter,
  completed = true,
): DangerZoneResult[] =>
  perimeter.position.map((item, index) => ({
    id: index,
    completed,
    zonePoints: item,
  }));

export function DangerZoneOverlay({
  selectedScenario,
  scenarioPerimeter,
  selectedZoneId,
  imageElement,
  editable,
  hideOnHover = false,
  onSelectedZoneUpdated,
  onZoneAdded,
  onZoneSelected,
  onClear,
}: Props) {
  const { setDangerZoneExportLayer } = useCameraContext();

  // state
  const [canvasSize, setCanvasSize] = useState<CanvasSize>({
    width: 0,
    height: 0,
  });
  const [curMousePos, setCurMousePos] = useState([0, 0]);
  const [editedCoordinates, setEditedCoordinates] = useState<XYCoordinate[]>();

  // reference
  const stageRef = useRef<Konva.Stage>(null);
  const dangerZoneLayerRef = useRef<Konva.Layer>(null);
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<CanvasRef>(null);

  const existingDangerZones = scenarioPerimeter
    ? convertToDangerZone(scenarioPerimeter)
    : [];

  const dangerZones = [
    ...existingDangerZones,
    ...(editedCoordinates
      ? [
          {
            id: existingDangerZones.length,
            completed: false,
            zonePoints: editedCoordinates,
          },
        ]
      : []),
  ];

  const imageWidth = imageElement?.width || 0;
  const imageHeight = imageElement?.height || 0;

  useEffect(() => {
    if (!editable) {
      setEditedCoordinates(undefined);
    }
  }, [editable]);

  const updateCanvasSize = useCallback(() => {
    setCanvasSize({
      width: imageWidth,
      height: imageHeight,
    });
  }, [imageWidth, imageHeight]);

  useEffect(() => {
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [updateCanvasSize]);

  const getMousePosition = (stage: Konva.Stage | null) => {
    const pointerPosition = stage?.getPointerPosition();
    return pointerPosition || undefined;
  };

  function getDividedOfPostions(partialValue: number, totalValue: number) {
    return partialValue / totalValue;
  }

  const checkXPointPostion = (x: number) => {
    if (x < 0) {
      return 15;
    }
    if (canvasSize.width < x) {
      return canvasSize.width - 15;
    }
    return getDividedOfPostions(x, canvasSize.width);
  };

  const checkYPointPostion = (y: number) => {
    if (y < 0) {
      return 0;
    }
    if (canvasSize.height < y) {
      return canvasSize.height;
    }
    return getDividedOfPostions(y, canvasSize.height);
  };

  const handleClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (!editable) {
      return;
    }

    const stage = event.target.getStage();
    const mousePos = getMousePosition(stage);
    if (!mousePos) {
      return;
    }

    const posX = checkXPointPostion(mousePos.x);
    const posY = checkYPointPostion(mousePos.y);
    const mouseCoordinates: XYCoordinate = [posX, posY];

    const isZoneCompleted = !!canvasRef.current?.getStartPoint();
    const updatedCoordinates = [...(editedCoordinates || []), mouseCoordinates];
    setEditedCoordinates(updatedCoordinates);
    onZoneSelected?.(existingDangerZones.length);

    if (isZoneCompleted) {
      onZoneAdded?.(updatedCoordinates);
      setEditedCoordinates(undefined);
    }
  };

  const handleDragEndPoint = (
    event: Konva.KonvaEventObject<MouseEvent>,
    pointerIndex: number,
  ) => {
    const selectedIndex = event.target.index - 1;
    const { x, y } = event.target.attrs;
    const posX = checkXPointPostion(x);
    const posY = checkYPointPostion(y);

    const pos: XYCoordinate = [posX, posY];
    const zonePoints = dangerZones
      .find((zone) => zone.id === pointerIndex)
      ?.zonePoints.map((point, index) =>
        index === selectedIndex ? pos : point,
      );

    if (zonePoints && onSelectedZoneUpdated) {
      onSelectedZoneUpdated(pointerIndex, zonePoints);
    }
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = event.target.getStage();
    const mousePos = getMousePosition(stage);
    if (!mousePos) {
      return;
    }

    const posX = checkXPointPostion(mousePos.x);
    const posY = checkYPointPostion(mousePos.y);

    setCurMousePos([posX, posY]);
  };

  useEffect(() => {
    setDangerZoneExportLayer(dangerZoneLayerRef.current);
  }, [dangerZoneLayerRef, setDangerZoneExportLayer]);

  return (
    <div
      className="canvas-box"
      ref={mainContainerRef}
      style={{
        pointerEvents: hideOnHover ? 'none' : 'auto',
      }}
    >
      <Stage
        ref={stageRef}
        width={canvasSize.width}
        height={canvasSize.height}
        onMouseDown={handleClick}
        onMouseMove={handleMouseMove}
        onClick={onClear}
      >
        <Layer ref={dangerZoneLayerRef}>
          {canvasSize.width > 0 &&
            canvasSize.height > 0 &&
            dangerZones.map((zone) => (
              <DangerZone
                ref={canvasRef}
                key={zone.id}
                selectedZoneColor={selectedScenario.color}
                dangerZone={zone}
                zoneSize={canvasSize}
                curMousePos={curMousePos}
                onDblTapGroup={onZoneSelected}
                selectedZoneIndex={selectedZoneId}
                onDragEnd={handleDragEndPoint}
              />
            ))}
        </Layer>
      </Stage>
    </div>
  );
}
