import React, { useState } from 'react';
import { Col, Dropdown, FormCheck } from 'react-bootstrap';
import { ReactComponent as CameraIcon } from '../../assets/icons/camera.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { CameraProps } from '../../typescript/camera/camera';
import i18n from '../../utils/i18n';

interface Props {
  className?: string | undefined;
  cameraList: Array<CameraProps>;
  selectedCameraList: Array<CameraProps>;
  loading?: boolean;

  onClickSelectedCameraList: (selectedCameras: Array<CameraProps>) => void;
}

export function CameraAction({
  className,
  cameraList,
  selectedCameraList,
  loading,
  onClickSelectedCameraList,
}: Props) {
  const [selectedAllCameras, setSelectedAllCameras] = useState<boolean>(false);
  const { theme } = useThemeContext();

  return (
    <Col md={12} lg={7} className={className}>
      <div className="title-div">
        <div className="circle">
          <CameraIcon />
        </div>
        <div className="body-div">
          <p className="mb-1 weight-600">{i18n.t('camera_action.title')}</p>
          <p className="sub-color">{i18n.t('camera_action.body')}</p>

          <Dropdown>
            <Dropdown.Toggle
              className="rounded-pill"
              variant={`outline-${theme}`}
              id="dropdown-basic"
            >
              {selectedCameraList.length === 0
                ? `${i18n.t('button.select_camera')}`
                : `${selectedCameraList.length} ${selectedCameraList.length === 1 ? i18n.t('button.camera_selected') : i18n.t('button.cameras_selected')}`}
            </Dropdown.Toggle>

            <Dropdown.Menu variant={theme}>
              <div className="dropdown-item">
                <FormCheck
                  className="check-box"
                  checked={selectedAllCameras}
                  label={i18n.t('button.select_all')}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      setSelectedAllCameras(!selectedAllCameras);
                      onClickSelectedCameraList(cameraList);
                    } else {
                      setSelectedAllCameras(!selectedAllCameras);
                      onClickSelectedCameraList([]);
                    }
                  }}
                />
              </div>

              {loading
                ? `${i18n.t('loading.title')}`
                : cameraList.map((item) => {
                    const isChecked: boolean = selectedCameraList.some(
                      (elemet) => elemet.id === item.id,
                    );
                    return (
                      <div
                        className="dropdown-item ph-no-capture"
                        key={item.id}
                      >
                        <FormCheck
                          checked={isChecked}
                          className="check-box"
                          label={item.name}
                          onClick={(e: any) => {
                            if (e.target.checked) {
                              onClickSelectedCameraList([
                                ...selectedCameraList,
                                item,
                              ]);
                            } else {
                              const withoutEl = selectedCameraList?.filter(
                                (elemet) => elemet.id !== item.id,
                              );
                              onClickSelectedCameraList(withoutEl);
                              setSelectedAllCameras(false);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Col>
  );
}
