import React from 'react';
import { Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import emptylist from '../../assets/empty-list.svg';
import i18n from '../../utils/i18n';
import { Button } from '../elements/Button';

type Props = {
  className?: string;
};

export function EmptyAlertsList({ className }: Props) {
  const navigate = useNavigate();

  return (
    <Col
      md={6}
      className={`
        text-center
        mx-auto
        align-self-center
        d-flex
        flex-column
        justify-content-center
        align-items-center
        flex-1
        ${className}
      `}
    >
      <Image className="mb-3" src={emptylist} />
      <h6>{i18n.t('alert.empty.list.title')}</h6>
      <p>{i18n.t('alert.empty.list.body')}</p>

      <Button
        title={i18n.t('button.setup_alert')}
        className="button-with-loader mx-auto mt-4"
        variant="primary"
        type="button"
        onClick={() => navigate('/alerts/create')}
      />
    </Col>
  );
}
