// Type guards
export const isString = (obj: unknown): obj is string =>
  typeof obj === 'string';

export const isNumber = (obj: unknown): obj is number =>
  typeof obj === 'number';

export const isDefined = <T>(obj: T | undefined): obj is T => obj !== undefined;

export const isUndefined = (obj: unknown): obj is undefined =>
  obj === undefined;

export const isNull = (obj: unknown): obj is null => obj === null;

export const isNotNull = <T>(obj: T | null): obj is T => obj !== null;

export const isArray = (obj: unknown): obj is unknown[] => Array.isArray(obj);

export const isArrayOf = <T>(
  obj: unknown,
  guard: (item: unknown) => item is T,
): obj is T[] => Array.isArray(obj) && obj.every(guard);

export const isEmptyArray = (obj: unknown): obj is never[] =>
  isArray(obj) && obj.length === 0;

export const isObject = (obj: unknown): obj is Record<string, unknown> =>
  typeof obj === 'object' && obj !== null;

export const isEmptyObject = (obj: unknown): obj is Record<string, never> =>
  isObject(obj) && Object.keys(obj).length === 0;

export const isNotEmptyObject = (
  obj: unknown,
): obj is Record<string, unknown> =>
  isObject(obj) && Object.keys(obj).length > 0;

export const isError = (obj: unknown): obj is Error => obj instanceof Error;

// Type assertions

/**
 * @throws {Error} This should be unreachable
 */
export const assertUnreachable = (_x?: never): never => {
  throw new Error('This should be unreachable');
};

// Type aliases

export type Maybe<T> = T | null | undefined;

export type Nullable<T> = T | null;
