/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker';
import { buttonCss } from './Button';
import { Loader } from './Loader';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { useTracker } from '../../utils/Tracker';

export type DropdownProps = {
  title: string | number;
  text?: string;
  value: string | number | boolean;
  end?: string | number | boolean;
  isDatePicker?: boolean;
  index?: number;
};

type Props = {
  containerClassName?: string;
  className?: string;
  hideContextData?: string;
  variant?: 'outline-light' | 'outline-dark';
  bodyVariant?: 'light' | 'dark';
  title?: string | number | null;
  dropdownData: Array<DropdownProps>;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  rightIcon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (value: DropdownProps) => any | undefined;
};

export function CustomDropdown({
  containerClassName = '',
  className = '',
  hideContextData = '',
  variant: variantProp,
  bodyVariant: bodyVariantProp,
  title = '',
  loading = false,
  disabled = false,
  dropdownData,
  icon: Icon,
  rightIcon: RightIcon,
  onClick,
}: Props) {
  const { theme } = useThemeContext();
  const { trackButtonClick } = useTracker();
  const [isOpen, setIsOpen] = useState(false);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);
  const variant = variantProp || `outline-${theme}`;
  const bodyVariant = bodyVariantProp || theme;

  return (
    <Dropdown
      className={containerClassName}
      show={isOpen}
      onToggle={() => {
        if (!isCalenderOpen) {
          setIsOpen(!isOpen);
        }
      }}
    >
      <Dropdown.Toggle
        className={`${className} rounded-pill icon button-with-loader`}
        variant={variant}
        css={css`
          ${buttonCss}
        `}
        id="dropdown-basic"
        disabled={loading || disabled}
      >
        {Icon && <Icon className="me-2" />}
        {title}
        {RightIcon && <RightIcon className="ms-2" />}
        {loading && <Loader className="ms-12" />}
      </Dropdown.Toggle>

      <Dropdown.Menu variant={bodyVariant}>
        {dropdownData.map((item) => {
          if (item.isDatePicker) {
            return (
              <DateRangePicker
                placement="auto"
                key={item.title}
                ranges={[]}
                className={`${bodyVariant}-dropdown-cal dropdown-cal`}
                placeholder={item.title}
                format="yyyy-MM-dd, HH:mm:ss"
                appearance="default"
                shouldDisableDate={(date) => {
                  const currentDate = new Date();
                  return date > currentDate;
                }}
                editable={false}
                value={
                  item.text === 'custom' && item.value === 0
                    ? null
                    : [
                        new Date(
                          moment
                            .unix(Number(item.value))
                            .format('YYYY-MM-DD, HH:mm:ss'),
                        ),
                        new Date(
                          moment
                            .unix(Number(item.end))
                            .format('YYYY-MM-DD, HH:mm:ss'),
                        ),
                      ]
                }
                onOpen={() => setIsCalenderOpen(true)}
                onClose={() => setIsCalenderOpen(false)}
                onChange={(dates: DateRange | null) => {
                  const convertedDates = dates
                    ? dates.map((date) => moment(date).unix())
                    : [];
                  if (onClick) {
                    const value = { ...item };
                    value.value = `${convertedDates}`;
                    onClick(value);
                  }
                }}
              />
            );
          }

          return (
            <Dropdown.Item
              variant="outline-secondary"
              className={`py-2 ${hideContextData}`}
              key={item.title}
              onClick={() => {
                if (onClick) {
                  trackButtonClick({
                    title: item.text || '',
                  });
                  onClick(item);
                }
              }}
            >
              {item.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
