/* eslint-disable react/no-unused-prop-types */
import moment from 'moment';
import React, { useState } from 'react';
import { Button } from './Button';
import { generateFile } from '../../apis/api-csv';
import { ReactComponent as CSVIcon } from '../../assets/icons/csv-icon.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';

type Props = {
  type?: string;
  scenarios?: Array<number>;
  camera_ids?: Array<number>;
  responder?: Array<string>;
  status?: Array<string>;
  priority?: Array<string>;
  start_time?: number;
  end_time?: number;
  is_bookmarked?: boolean;
  limit?: number;
  disable?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};
export function CSVDownloader({
  type,
  scenarios,
  camera_ids,
  responder,
  status,
  priority,
  start_time,
  end_time,
  is_bookmarked,
  limit,
  disable,
}: Props) {
  const { theme } = useThemeContext();
  const { userCookie, isCSVStatus, scenariosList, setIsCSVStatus } =
    useAuthContext();
  const [loader, setLoader] = useState(false);

  return (
    <Button
      title={i18n.t('button.export_as_csv')}
      className="button-with-loader ms-12"
      variant={`outline-${theme}`}
      icon={CSVIcon}
      onClick={async () => {
        const filteredScenario = scenariosList.map((item) => item.id || 0);
        setLoader(true);
        const generate_file = await generateFile(userCookie.userToken, {
          type: type || 'CSV_EXPORT',
          scenarios: scenarios || filteredScenario,
          camera_ids,
          responder,
          status,
          priority,
          limit,
          start_time,
          is_bookmarked,
          end_time: end_time || moment().unix(),
        });
        setLoader(false);
        if (generate_file.status === 200) {
          setIsCSVStatus('pending');
        }
      }}
      loading={loader}
      disabled={disable || loader || isCSVStatus !== null}
    />
  );
}
