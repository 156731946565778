import React from 'react';
import { ReactComponent as VerticalDotsIcon } from '../../../assets/icons/vertical-dots.svg';
import { useAuthContext } from '../../../contextapi/AuthProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { useTableAction } from '../../../hooks/table-actions';
import { ObservationProp } from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import { CustomDropdownWithSubMenu } from '../../elements/CustomDropdownWithSubMenu';

export type ObservationUpdate = {
  value: string | number | boolean;
  subMenu?: { title: string | number; value: string | number | boolean }[];
};

export type ObservationActionEventHandler = (
  observation: ObservationProp,
  action: 'report' | 'bookmark' | 'remove_bookmark' | 'view_details' | string,
  value?: string,
) => void;

type Props = {
  observation: ObservationProp;
  onObservationAction: ObservationActionEventHandler;
  isThreeDot?: boolean;
  hideReportFalsePositive?: boolean;
};

export function ObservationActions({
  observation,
  onObservationAction,
  isThreeDot = false,
  hideReportFalsePositive = false,
}: Props) {
  const { theme } = useThemeContext();
  const { user } = useAuthContext();
  const { dropdownActionList } = useTableAction();

  const dropdownList = dropdownActionList
    .filter((action) => action.value !== 'bookmark')
    .filter((action) => !hideReportFalsePositive || action.value !== 'report')
    .map((item) => ({
      ...item,
      title: i18n.t(`${item.title}`),
    }));

  const userId = user?.id;
  if (userId) {
    const isBookmarked = observation.observation_users.some(
      ({ user_id }) => user_id === userId,
    );

    if (isBookmarked) {
      dropdownList.push({
        title: i18n.t('button.remove_bookmark'),
        value: 'remove_bookmark',
      });
    } else {
      dropdownList.push({
        title: i18n.t('button.bookmark'),
        value: 'bookmark',
      });
    }
  }

  return (
    <CustomDropdownWithSubMenu
      mainStyle={{ position: 'static' }}
      dropdownData={dropdownList}
      hideContextData="ph-no-capture"
      title={!isThreeDot ? `${i18n.t('button.actions')}` : undefined}
      isThreeDot={isThreeDot}
      icon={isThreeDot ? VerticalDotsIcon : undefined}
      variant={`outline-${theme}`}
      bodyVariant={theme}
      onClick={(option) => {
        const { value: action, subMenu } = option;
        const value = subMenu ? String(subMenu[0].value) : undefined;

        onObservationAction(observation, String(action), value);
      }}
    />
  );
}
