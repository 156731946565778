import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import { CameraProps } from '../../../typescript/camera/camera';
import i18n from '../../../utils/i18n';
import {
  CustomDropdownWithCheckbox,
  DropdownProps,
} from '../../elements/CustomDropdownWithCheckbox';

type Props = {
  cameraList: Array<CameraProps>;
  selectedCameraList: Array<CameraProps>;
  onClickSelectedCameraList: (selectedCameras: Array<DropdownProps>) => void;
};

export function CameraFilter({
  cameraList,
  selectedCameraList,
  onClickSelectedCameraList,
}: Props) {
  const { theme } = useThemeContext();
  const [allCameraList, setAllCameraList] = useState<Array<DropdownProps>>([]);
  const [selectAllCameras, setSelectAllCameras] = useState<boolean>(false);

  useEffect(() => {
    if (cameraList) {
      const cameras = cameraList.map((item) => ({
        title: item.name,
        value: item.sql_id,
        isChecked: selectedCameraList.some((o2) => item.sql_id === o2.sql_id),
      }));
      setAllCameraList(cameras);
    }
  }, [cameraList, selectedCameraList]);

  return (
    <Col md={12}>
      <hr />
      <p className="mb-1 weight-600">{i18n.t('filter.camera.title')}</p>
      <p className="sub-color">{i18n.t('filter.camera.body')}</p>

      <CustomDropdownWithCheckbox
        onSelectedAll={(value, fromChild) => {
          if (!fromChild) {
            const selectAll = allCameraList.map((item) => ({
              title: item.title,
              value: item.value,
              isChecked: value,
            }));

            setAllCameraList(selectAll);
            onClickSelectedCameraList(
              selectAll.filter((camera) => camera.isChecked),
            );
          }

          setSelectAllCameras(value);
        }}
        selectedAll={selectAllCameras}
        className="rounded-pill"
        title={`${i18n.t('button.show_all_cameras')}`}
        selectAllTitle={`${i18n.t('button.select_all')}`}
        dropdownData={allCameraList}
        hideContextData="ph-no-capture"
        variant={`outline-${theme}`}
        bodyVariant={theme}
        onClick={(value) => {
          const selectAll = allCameraList.map((item) => {
            if (item.value === value.value) {
              return {
                title: item.title,
                value: item.value,
                isChecked: !item.isChecked,
              };
            }
            return item;
          });
          setAllCameraList(selectAll);
          onClickSelectedCameraList(
            selectAll.filter((camera) => camera.isChecked),
          );
        }}
      />
    </Col>
  );
}
