/* eslint-disable import/no-extraneous-dependencies */
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { Button } from './Button';
import { BUDDYWISE_API_URL } from '../../apis/api-request';
import { siteId } from '../../apis/tokens';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';

type Props = {
  imageUrl?: string;
  onClick?: () => void;
};

export function ImageDownloader({ imageUrl, onClick }: Props) {
  const { theme } = useThemeContext();
  const { userCookie } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDownload =
    onClick ||
    (imageUrl
      ? () => {
          setLoading(true);
          setLoading(true);
          fetch(
            `${BUDDYWISE_API_URL}/v2/images/download-image/?url=${btoa(imageUrl)}`,
            {
              method: 'get',
              headers: {
                Authorization: `Bearer ${userCookie.userToken}`,
                ...(siteId && { 'X-Site-Id': `${siteId}` }),
              },
            },
          )
            .then((response) => response.blob())
            .then((blob) => {
              saveAs(blob, 'image.jpg');
            })
            .catch((error) => {
              console.error('Error downloading image:', error);
            })
            .finally(() => setLoading(false));
        }
      : undefined);

  return (
    <Button
      disabled={loading}
      loading={loading}
      title={`${i18n.t('button.download')}`}
      icon={DownloadIcon}
      iconProps={{ stroke: '#1F2321' }}
      onClick={handleDownload}
      className="button-with-loader"
      variant={`outline-${theme}`}
    />
  );
}
