import { saveAs } from 'file-saver';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from './Loader';
import { baseURL } from '../../apis/api-csv';
import { siteId } from '../../apis/tokens';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import i18n from '../../utils/i18n';
import { useTracker } from '../../utils/Tracker';

interface Props {
  className?: string;
  variant?: string;
  title?: string;
  buttonText?: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}
const BottomToast: FC<Props> = function BottomToast({
  className,
  variant,
  title,
  buttonText,
  icon: Icon,
  onClick,
}) {
  const { userCookie, setIsCSVStatus, isCSVStatus } = useAuthContext();
  const { trackButtonClick } = useTracker();
  const [loading, setLoading] = useState<boolean>(false);
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);

  const handleDownload = () => {
    setLoading(true);
    fetch(
      `${baseURL}/api/v2/file-endpoint/download-file?file_type=CSV_EXPORT`,
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${userCookie.userToken}`,
          ...(siteId && { 'X-Site-Id': `${siteId}` }),
        },
      },
    )
      .then((response) => {
        const blob = response.blob();
        if (response.status === 404) {
          toast.error(`Error ${response.status}`);
          return null;
        }
        return blob;
      })
      .then((blob) => {
        if (blob) {
          saveAs(blob, 'export_csv.csv');
        }
      })
      .catch((error) => {
        console.error('Error downloading CSV:', error);
      })
      .finally(() => {
        setIsCSVStatus(null);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setCancelLoading(true);
    fetch(`${baseURL}/api/v2/file-endpoint/cancel-file?file_type=CSV_EXPORT`, {
      method: 'get',
      headers: {
        Authorization: `Bearer ${userCookie.userToken}`,
        ...(siteId && { 'X-Site-Id': `${siteId}` }),
      },
    })
      .then((response) => console.error('Api is call for cancel:', response))
      .catch((error) => {
        console.error('Error downloading CSV:', error);
      })
      .finally(() => {
        setIsCSVStatus(null);
        setCancelLoading(false);
      });
  };

  return (
    <div className={`${className} bottom-toast bottom-toast-${variant}`}>
      {Icon && <Icon className="me-2" />}
      <span className="me-12">{title}</span>
      {isCSVStatus === 'done' && !cancelLoading && (
        <button
          type="button"
          className="bottom-toast-button"
          disabled={loading}
          onClick={(e) => {
            trackButtonClick({
              title: buttonText || '',
            });
            handleDownload();
            if (onClick) {
              onClick(e);
            }
          }}
        >
          {!loading && (
            <>
              <DownloadIcon className="me-2" />
              {buttonText}
            </>
          )}
          {loading && <Loader className="bottom-toast-loader" />}
        </button>
      )}
      <button
        type="button"
        className="bottom-toast-button"
        disabled={cancelLoading}
        onClick={(e) => {
          handleCancel();
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {!cancelLoading && i18n.t('button.cancel')}
        {cancelLoading && <Loader className="bottom-toast-loader" />}
      </button>
    </div>
  );
};

BottomToast.defaultProps = {
  className: '',
  variant: '',
  title: '',
  buttonText: '',
  icon: undefined,
  onClick: undefined,
};

export default BottomToast;
