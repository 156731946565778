import React, { FC, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  defaultModalValue,
  ModalType,
} from '../../../typescript/components/modal';
import i18n from '../../../utils/i18n';
import { Button } from '../../elements/Button';
import { ConfirmationModal } from '../../modal/ConfirmationModal';

interface Props {
  onCloseClick: () => void;
  hideParentModal: () => void;
}

const RemoveCamera: FC<Props> = function RemoveCamera({
  onCloseClick,
  hideParentModal,
}) {
  const { theme } = useThemeContext();
  const { camera, removeCamera } = useCameraContext();

  const [loading, setLoading] = useState<boolean>(false);

  // Modal
  const [confirmationModal, setConfirmationModal] =
    useState<ModalType>(defaultModalValue);

  const doRemoveCamera = async () => {
    if (!camera) return;

    setLoading(true);
    const success = await removeCamera(camera.sql_id);
    if (success) {
      onCloseClick();
    }
    setLoading(false);
  };

  const handleShowConfirmation = (isConfirmed: boolean) => {
    if (isConfirmed) doRemoveCamera();
    hideParentModal();
    setConfirmationModal(defaultModalValue);
  };

  if (!camera) return null;

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('camera.remove_camera.title')}</p>
      <p className="sub-color">{i18n.t('camera.remove_camera.body')}</p>

      <Button
        variant={`outline-${theme}`}
        type="button"
        className="button-with-loader"
        title={i18n.t('button.remove_camera')}
        loading={loading}
        onClick={() => {
          hideParentModal();
          setConfirmationModal({
            title: i18n.t('modal.delete_camera.title'),
            body: i18n.t('modal.delete_camera.body'),
            buttonType: 'danger',
            doneText: i18n.t('modal.delete_observation.yes'),
            cancelText: i18n.t('modal.delete_observation.no'),
            show: true,
          });
        }}
      />
      <ConfirmationModal
        modalValue={confirmationModal}
        onCloseClick={handleShowConfirmation}
      />
    </Col>
  );
};

export default RemoveCamera;
