import React from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { Button } from '../elements/Button';
import ImageViewer from '../elements/ImageViewer';

interface Props {
  imageUrl: string;
  showModal: boolean;
  onCloseClick: () => void;
}

export function ShowImageModal({ showModal, imageUrl, onCloseClick }: Props) {
  const { theme } = useThemeContext();

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal observation-image danger-zone-modal"
      show={showModal}
      onHide={() => onCloseClick()}
    >
      <Modal.Header>
        <Modal.Title>{/* {title} */}</Modal.Title>
        <Button
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={() => onCloseClick()}
        />
      </Modal.Header>
      <Modal.Body>
        <ImageViewer imageUrl={imageUrl} />
      </Modal.Body>
    </Modal>
  );
}
