import React from 'react';
import { Image, Navbar } from 'react-bootstrap';
import { MenuItem } from './MenuItem';
import logo from '../../assets/logo-icon-dark.svg';
import lightLogo from '../../assets/logo-icon-light.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';

function SideNavBar() {
  const { theme } = useThemeContext();
  return (
    <div className={`side-nav border-end border-${theme}`}>
      <Navbar expand="lg" bg={theme} variant={theme} className="m-2 m-lg-0">
        <Navbar.Brand href="/" className="p-0">
          <Image
            src={theme === 'light' ? logo : lightLogo}
            className="mt-lg-4"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <MenuItem />
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default SideNavBar;
