import React, { useEffect, useState } from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { ReactComponent as IntervalIcon } from '../../assets/icons/refresh-clock.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { intercalList } from '../../typescript/smart-alert/old-smart-alert';
import i18n from '../../utils/i18n';

type Props = {
  className?: string | undefined;
  selectedInterval: { type: number; value: number };
  onClick: (type: number, value: number) => void;
};

export function IntervalAction({
  className,
  selectedInterval,
  onClick,
}: Props) {
  const [interval, setInterval] = useState<{ type: number; value: number }>(
    selectedInterval,
  );
  const { theme } = useThemeContext();

  useEffect(() => {
    setInterval(selectedInterval);
  }, [selectedInterval]);

  return (
    <Col md={12} lg={7} className={className}>
      <div className="title-div">
        <div className="circle">
          <IntervalIcon />
        </div>
        <div className="body-div">
          <p className="mb-1 weight-600">{i18n.t('select_interval.title')}</p>
          <p className="sub-color">{i18n.t('select_interval.body')}</p>

          <div className="d-flex">
            <Dropdown>
              <Dropdown.Toggle
                className="rounded-pill"
                variant={`outline-${theme}`}
                id="dropdown-basic"
              >
                {i18n.t(intercalList[interval.type])}
              </Dropdown.Toggle>

              <Dropdown.Menu variant={theme}>
                {intercalList.map((item: string, index: number) => (
                  <Dropdown.Item
                    key={item}
                    onClick={() => {
                      const intervalValue = { type: index, value: 60 };
                      if (index === 0) {
                        setInterval(intervalValue);
                        onClick(intervalValue.type, intervalValue.value);
                      } else if (index === 1) {
                        intervalValue.value = 3600;
                        setInterval(intervalValue);
                        onClick(intervalValue.type, intervalValue.value);
                      } else if (index === 2) {
                        intervalValue.value = 86400;
                        setInterval(intervalValue);
                        onClick(intervalValue.type, intervalValue.value);
                      }
                    }}
                  >
                    {i18n.t(item)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="ms-3 interval-dropdowns">
              <Dropdown.Toggle
                className="rounded-pill"
                variant={`outline-${theme}`}
                id="dropdown-basic"
              >
                {/* Minutes */}
                {interval.type === 0 && interval.value / 60}
                {/* Hours */}
                {interval.type === 1 && interval.value / 60 / 60}
                {/* Days */}
                {interval.type === 2 && interval.value / 24 / 60 / 60}
              </Dropdown.Toggle>

              <Dropdown.Menu variant={theme}>
                {interval.type === 0 &&
                  [...Array(59)].map((x, item) => {
                    const time = item + 1;
                    return (
                      <Dropdown.Item
                        key={x}
                        onClick={() => {
                          const intervalValue = {
                            type: interval.type,
                            value: time * 60,
                          };
                          setInterval(intervalValue);
                          onClick(intervalValue.type, intervalValue.value);
                        }}
                      >
                        {`${time} m`}
                      </Dropdown.Item>
                    );
                  })}

                {interval.type === 1 &&
                  [...Array(23)].map((x, item) => {
                    const time = item + 1;
                    return (
                      <Dropdown.Item
                        key={x}
                        onClick={() => {
                          const intervalValue = {
                            type: interval.type,
                            value: time * 60 * 60,
                          };
                          setInterval(intervalValue);
                          onClick(intervalValue.type, intervalValue.value);
                        }}
                      >
                        {`${time} h`}
                      </Dropdown.Item>
                    );
                  })}

                {interval.type === 2 &&
                  [...Array(30)].map((x, item) => {
                    const time = item + 1;
                    return (
                      <Dropdown.Item
                        key={x}
                        onClick={() => {
                          const intervalValue = {
                            type: interval.type,
                            value: time * 24 * 60 * 60,
                          };
                          setInterval(intervalValue);
                          onClick(intervalValue.type, intervalValue.value);
                        }}
                      >
                        {`${time} d`}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </Col>
  );
}
