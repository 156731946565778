import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ModalType } from '../../typescript/components/modal';
import { Button } from '../elements/Button';

type Props = {
  modalValue: ModalType;
  onCloseClick: (isConfirmed: boolean) => void;
};
export function ConfirmationModal({ modalValue, onCloseClick }: Props) {
  const { theme } = useThemeContext();
  const { icon: Icon } = modalValue;

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onCloseClick(true);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <Modal
      centered
      contentClassName={theme}
      className="custom-modal"
      show={modalValue.show}
      onHide={() => {
        if (!modalValue.loading) {
          onCloseClick(false);
        }
      }}
    >
      <Modal.Header>
        <Modal.Title>{/* {title} */}</Modal.Title>
        <Button
          icon={CancelIcon}
          variant="transparent"
          className="border-0"
          type="button"
          onClick={() => {
            if (!modalValue.loading) {
              onCloseClick(false);
            }
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12} className="text-start">
            {Icon && <Icon className="mb-16" />}
            <h6>{modalValue.title}</h6>
            <p className="sub-color">{modalValue.body}</p>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button
          disabled={modalValue.loading}
          title={modalValue.cancelText}
          className="button-with-loader"
          variant={`outline-${theme}`}
          type="button"
          onClick={() => onCloseClick(false)}
        />
        <Button
          disabled={modalValue.loading}
          loading={modalValue.loading}
          title={modalValue.doneText}
          className="button-with-loader"
          variant={modalValue.buttonType}
          type="button"
          onClick={() => onCloseClick(true)}
        />
      </Modal.Footer>
    </Modal>
  );
}
