import axios from 'axios';

export const TRANSLATIONS_API_BASE_URL = `${process.env.REACT_APP_TRADUORA_URL}/api/v1`;

const CLIENT_ID = process.env.REACT_APP_TRADUORA_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_TRADUORA_CLIENT_SECRET;
const PROJECT_ID = process.env.REACT_APP_TRADUORA_PROJECT_ID;

const traduoraClient = axios.create({
  baseURL: TRANSLATIONS_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

type AuthResponse = {
  access_token: string;
  token_type: string;
  expires_in: string;
};

const fetchTraduoraAuthToken = async () => {
  try {
    const authResponse = await traduoraClient.post<AuthResponse>(
      '/auth/token',
      {
        grant_type: 'client_credentials',
        client_id: CLIENT_ID,
        client_secret: CLIENT_SECRET,
      },
    );

    return authResponse.data.access_token;
  } catch (error) {
    console.error('Error fetching auth token:', error);
    throw error;
  }
};

export type TranslationCatalog = Record<string, string>;

export const fetchTranslationCatalog = async (locale: string) => {
  const authToken = await fetchTraduoraAuthToken();

  try {
    const response = await traduoraClient.get<TranslationCatalog>(
      `projects/${PROJECT_ID}/exports?locale=${locale}&format=jsonflat`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching translation catalog:', locale, error);
    throw error;
  }
};
