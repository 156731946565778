import React from 'react';
import { Col } from 'react-bootstrap';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import i18n from '../../utils/i18n';
import { InputField } from '../elements/InputField';

type Props = {
  className?: string | undefined;
  alertName?: string | number | string[] | undefined;
  onTextChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
};

export function NameOfAlertRuleAction({
  className,
  alertName,
  onTextChange,
}: Props) {
  const { theme } = useThemeContext();

  return (
    <Col md={12} lg={7} className={className}>
      <div className="title-div">
        <div className="circle">
          <EditIcon />
        </div>
        <div className="body-div">
          <p className="mb-1 weight-600">{i18n.t('name_of_the_alert.title')}</p>
          <p className="sub-color">{i18n.t('name_of_the_alert.body')}</p>

          <InputField
            className={`mb-12 input-${theme}`}
            controlId="formCameraName"
            type="text"
            value={alertName}
            placeholder={`${i18n.t('input.alert_name_placeholder')}`}
            onChange={onTextChange}
          />
        </div>
      </div>
    </Col>
  );
}
