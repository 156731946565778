/* eslint-disable max-len */
import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AlertsTable } from './AlertsTable';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { ALERT_INCIDENTS, WhereQueryProps } from '../../graphql/queries/alerts';
import {
  AlertIncidents,
  AlertProps,
} from '../../typescript/smart-alert/smart-alert';
import { Loader } from '../elements/Loader';

export function AlertList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get('pageNo')
    ? Number(searchParams.get('pageNo'))
    : 1;
  const limitParam = searchParams.get('limit')
    ? Number(searchParams.get('limit'))
    : null;
  const decode: string = searchParams.get('filter')
    ? decodeURI(String(searchParams.get('filter')))
    : '{}';

  const [limit, setLimit] = useState(limitParam || 10);

  const filter: WhereQueryProps = JSON.parse(decode);

  const defaultOffset = pageNumber === 1 && 0;
  const offset = defaultOffset ? 0 : (pageNumber - 1) * limit;

  const [queryVar, setQueryVar] = useState<WhereQueryProps | null>(filter);

  // Context
  const { onTokenSave } = useAuthContext();

  const [alerts, setAlerts] = useState<Array<AlertIncidents>>([]);
  const [alert, setAlert] = useState<Array<AlertProps>>([]);

  // Pagination
  const [totalPages, setTotalPage] = useState(0);

  const [isOrder, setIsOrder] = useState(true);

  const { loading, error, data, refetch } = useQuery(ALERT_INCIDENTS, {
    variables: {
      limit,
      offset,
      where: {
        ...queryVar,
      },
      orderBy: !isOrder ? 'asc' : 'desc',
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setTotalPage(Math.ceil(data.alertAggregate.aggregate.count / limit));
      setAlerts(data.alerts);
      setAlert(data.alert);
    }
  }, [data, refetch, searchParams, queryVar, limit]);

  useEffect(() => {
    if (error?.message === 'Authentication hook unauthorized this request') {
      onTokenSave('');
      refetch();
    }
  }, [error, onTokenSave, refetch]);

  // const onSensitiveObservation = (observationIds: Array<number>, column: string, value: string) => {
  //   // updateSensitiveObservation({
  //   //   variables: {
  //   //     id: observationIds,
  //   //     column,
  //   //     value,
  //   //   },
  //   // });
  // };

  // const onUpdateObservation = (observationIds: Array<number>, value: ObservationsSetInput) => {
  //   // updateObservation({
  //   //   variables: {
  //   //     observationIds,
  //   //     data: value,
  //   //   },
  //   // });
  // };

  const onFilterApplied = (
    whereQuery: WhereQueryProps | null,
    listLimit: number,
    pageNo: number,
  ) => {
    setQueryVar(whereQuery);
    setLimit(listLimit);

    const isFilter = whereQuery ? `&filter=${JSON.stringify(whereQuery)}` : '';
    const encodeUrl = encodeURI(isFilter);

    navigate({
      pathname: '/alerts',
      search: `?pageNo=${pageNo}&limit=${listLimit}${encodeUrl}`,
    });
  };

  if (loading) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center empty-list">
        <Loader main />
      </div>
    );
  }

  return (
    <AlertsTable
      alerts={alerts}
      alertRules={alert}
      filter={filter}
      limit={limit}
      pageNumber={pageNumber}
      totalPages={totalPages}
      isOrder={isOrder}
      setIsOrder={setIsOrder}
      setQueryVar={setQueryVar}
      onSensitiveAlert={() => console.warn()}
      onUpdateAlert={() => console.warn()}
      onFilterApplied={onFilterApplied}
    />
  );
}
