import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import VisibilityObserver from 'react-visibility-observer';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ObservationProp } from '../../typescript/observation/observation';
import i18n from '../../utils/i18n';
import { LazyLoadedImage } from '../elements/LazyLoadedImage';

interface Props {
  observation: ObservationProp;
  onImageClick: (selectedAlert: ObservationProp) => void;
}

export function ObservationItem({ observation, onImageClick }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList, clientUsersList } = useAuthContext();

  const [observationDetail, setObservationDetail] =
    useState<ObservationProp>(observation);

  const filteredScenario = scenariosList.filter(
    (scenario) =>
      scenario.value === observationDetail.customer_scenario_label.name,
  );

  const filteredAssignee = clientUsersList.filter(
    (item) => item.email === observation.responder,
  )[0];

  return (
    <>
      <div className="incidents-list">
        <VisibilityObserver triggerOnce threshold={0.2}>
          <LazyLoadedImage
            onImageClick={(udpatedObservation) => {
              onImageClick(udpatedObservation);
              setObservationDetail(udpatedObservation);
            }}
            observation={observationDetail}
          />
        </VisibilityObserver>
        <div className="content">
          <div className="d-flex align-items-center mb-3">
            <p className="m-0" style={{ minWidth: 70 }}>
              {i18n.t('td.created')}:
            </p>
            <p className="m-0 ph-no-capture">
              {moment
                .unix(observation?.system_timestamp)
                .format('MMM DD, HH:mm:ss')}
            </p>
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="m-0" style={{ minWidth: 70 }}>
              {i18n.t('td.scenario')}:
            </p>
            <p className="m-0">
              {!filteredScenario[0] ? (
                i18n.t('td.scenario_not_found')
              ) : (
                <span
                  className="scenario-list"
                  style={{
                    backgroundColor:
                      theme === 'light'
                        ? filteredScenario[0].colorLight
                        : filteredScenario[0].color,
                    color:
                      theme === 'light' ? filteredScenario[0].color : '#fff',
                  }}
                >
                  {i18n.t(filteredScenario[0].title)}
                </span>
              )}
            </p>
          </div>

          <div className="d-flex align-items-center mb-3">
            <p className="m-0" style={{ minWidth: 70 }}>
              {i18n.t('td.assignee')}:
            </p>
            {observation.responder && filteredAssignee ? (
              <Link
                className="d-inline ph-no-capture"
                to={`/profile/${filteredAssignee.id}`}
              >
                {filteredAssignee.username || observation.responder}
              </Link>
            ) : (
              i18n.t('td.not_assigned')
            )}
          </div>
        </div>
      </div>

      <hr />
    </>
  );
}
