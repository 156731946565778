/* eslint-disable max-len */
import moment from 'moment';
import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as VerticalDotsIcon } from '../../assets/icons/vertical-dots.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { AlertProps } from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';

interface Props {
  alert: AlertProps;
  onEdit?: React.MouseEventHandler<HTMLElement> | undefined;
  onDelete?: React.MouseEventHandler<HTMLElement> | undefined;
}

export function RuleItem({ alert, onDelete, onEdit }: Props) {
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();

  const filteredCamera = alert.alert_rule_cameras;

  const filteredScenario = scenariosList.filter(
    (scenario) => scenario.id === Number(alert.customer_scenario_label_id),
  );

  return (
    <tr onClick={onEdit} className="add-cursor">
      <td>
        <div className="alert-rule">
          <div>
            <p className="mb-1 weight-600 ph-no-capture">{alert.name}</p>
            <p className="sub-color ph-no-capture">
              {alert.last_triggered !== null
                ? `${i18n.t('alert.timestamp.triggered')} ${moment(alert.last_triggered).fromNow()}`
                : i18n.t('alert.timestamp.not_triggered')}
            </p>
          </div>
        </div>
      </td>
      <td>
        {!filteredScenario[0] ? (
          i18n.t('td.scenario_not_found')
        ) : (
          <span
            className="scenario-list ph-no-capture"
            style={{
              backgroundColor:
                theme === 'light'
                  ? filteredScenario[0].colorLight
                  : filteredScenario[0].color,
              color: theme === 'light' ? filteredScenario[0].color : '#fff',
            }}
          >
            {i18n.t(filteredScenario[0].title)}
          </span>
        )}
      </td>
      <td>
        {filteredCamera.slice(0, 4).map((item, index) => (
          <span
            key={`${index + 1}`}
            className="camera-name rounded-pill ph-no-capture"
          >
            {item.camera
              ? item.camera.name
              : i18n.t('td.camera_no_longer_exists')}
            {/* {item.camera.name} */}
          </span>
        ))}

        {filteredCamera.length > 4 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                <span className="ph-no-capture">
                  {filteredCamera
                    .slice(4, filteredCamera.length - 1)
                    .map((item) => item.camera.name)
                    .toString()
                    .replaceAll(',', ', ')}
                </span>
              </Tooltip>
            }
          >
            <span className="show-more-cameras ph-no-capture">
              {filteredCamera.slice(4, filteredCamera.length - 1).length}{' '}
              {i18n.t('list.others')}
            </span>
          </OverlayTrigger>
        )}
      </td>
      <td>
        {alert.alert_actions.map((item) => (
          <span
            key={item.alert_site_channel.alert_channel.alert_type_name}
            className="camera-name rounded-pill ph-no-capture"
          >
            {i18n.t(
              `alert.channel_name.${item.alert_site_channel.alert_channel.alert_type_name}`,
            )}
          </span>
        ))}
      </td>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          <Dropdown
            className="three-dot-dropdown position-absolute"
            onClick={(e) => e.stopPropagation()}
          >
            <Dropdown.Toggle
              className="rounded-circle button-with-loader p-0"
              style={{ width: 40, height: 40 }}
              variant={`outline-${theme} text-capitalize me-2`}
              id="dropdown-basic"
            >
              <VerticalDotsIcon className="m-0 icon" />
            </Dropdown.Toggle>

            <Dropdown.Menu variant={theme}>
              <Dropdown.Item onClick={onEdit}>
                {i18n.t('button.edit')}
              </Dropdown.Item>
              <Dropdown.Item onClick={onDelete}>
                {i18n.t('button.delete')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </td>
    </tr>
  );
}
