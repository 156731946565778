import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as VerticalDotsIcon } from '../../assets/icons/vertical-dots.svg';
import { useAuthContext } from '../../contextapi/AuthProvider';
import { useCameraContext } from '../../contextapi/CameraProvider';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { AlertIncidents } from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';
import {
  CustomDropdownWithSubMenu,
  DropdownProps,
} from '../elements/CustomDropdownWithSubMenu';

interface Props {
  alert: AlertIncidents;
  selectedAlerts: boolean;
  dropdownActionList: Array<DropdownProps>;
  onReportFalseModal: (id: number) => void;
  onSensitiveAlertAction: (id: number, type: string, value: string) => void;
}

export function AlertItem({
  alert,
  selectedAlerts,
  dropdownActionList,
  onReportFalseModal,
  onSensitiveAlertAction,
}: Props) {
  const navigate = useNavigate();
  const { theme } = useThemeContext();
  const { scenariosList } = useAuthContext();
  const { cameraList } = useCameraContext();
  const [alertDetail, setAlertDetail] = useState<AlertIncidents>(alert);

  useEffect(() => {
    setAlertDetail(alertDetail);
  }, [alert, selectedAlerts]);

  const filteredCamera = alert.alert_rule_state.triggering_camera_ids
    ? cameraList.filter((o1) =>
        alert.alert_rule_state.triggering_camera_ids.some(
          (o2) => o1.sql_id === o2,
        ),
      )
    : [];

  const filteredScenario = scenariosList.filter(
    (scenario) =>
      scenario.id === Number(alert.alert_rule_state.customer_scenario_label_id),
  );

  // const filteredPriority = priorityList.filter(
  //   (priority) => priority.value === alertDetail.priority,
  // );

  // const filteredStatus = statusList.filter(
  //   (status) => status.value === alertDetail?.status,
  // )[0] || null;

  // const priorityHighLevel = filteredPriority.length ? filteredPriority[0].value === 'high' && 'background-error' : 'background-success';
  // const priorityMediumLevel = filteredPriority.length ? filteredPriority[0].value === 'medium' && 'background-warning' : 'background-success';
  // const priorityLowLevel = filteredPriority.length ? filteredPriority[0].value === 'low' && 'background-success' : 'background-success';

  function navigation() {
    navigate(`/alerts/${alert.id}`);
  }

  const dropdownList = dropdownActionList.map((item) => ({
    ...item,
    title: i18n.t(`${item.title}`),
  }));

  const date = moment(alert.created_at).unix();

  return (
    <tr
      className={`add-cursor selected-row ${selectedAlerts && `selected-row-${theme}`}`}
    >
      <td className="p-0" onClick={() => navigation()}>
        <div className="d-flex align-items-center">
          {/* <div
            className="ps-3 py-3"
            onClick={(e) => {
              isAlertSelected(!selectedAlerts, alertDetail.id);
            }}
            aria-hidden
          >
            <FormCheck
              checked={selectedAlerts}
              className="me-12"
              readOnly
            />
          </div> */}
          <span className="ps-3 py-3 ms-0 ph-no-capture">
            {moment.unix(date).format('DD MMM, HH:mm:ss')}
          </span>
        </div>
      </td>
      <td onClick={() => navigation()}>
        <p className="mb-1 ph-no-capture">
          {alertDetail.alert_rule_state
            ? alertDetail.alert_rule_state.name
            : 'this alert is deleted'}
        </p>
      </td>
      {/* <td onClick={() => navigation()}>
        <div className="alert-rule">
          <div>
            <p className="mb-1 weight-600 ph-no-capture">{alertDetail.name}</p>
            <p className="sub-color ph-no-capture">
              {alertDetail.last_triggered !== null ? `${i18n.t('alert.timestamp.triggered')} ${moment(alertDetail.last_triggered).fromNow()}` : i18n.t('alert.timestamp.not_triggered')}
            </p>
          </div>
        </div>
      </td> */}

      <td onClick={() => navigation()}>
        {!filteredScenario[0] ? (
          <span>{i18n.t('td.scenario_not_found')}</span>
        ) : (
          <span
            className="scenario-list"
            style={{
              backgroundColor:
                theme === 'light'
                  ? filteredScenario[0].colorLight
                  : filteredScenario[0].color,
              color: theme === 'light' ? filteredScenario[0].color : '#fff',
            }}
          >
            {i18n.t(filteredScenario[0].title)}
          </span>
        )}
      </td>

      <td onClick={() => navigation()}>
        {filteredCamera.length === 0 && (
          <span className="camera-name rounded-pill ph-no-capture">
            {i18n.t('td.camera_no_longer_exists')}
          </span>
        )}
        {filteredCamera.slice(0, 4).map((item, index) => (
          <span
            key={`${index + 1}`}
            className="camera-name rounded-pill ph-no-capture"
          >
            {item ? item.name : i18n.t('td.camera_no_longer_exists')}
          </span>
        ))}

        {filteredCamera.length > 4 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="button-tooltip-2">
                <span className="ph-no-capture">
                  {filteredCamera
                    .slice(4, filteredCamera.length - 1)
                    .map((item) => item.name)
                    .toString()
                    .replaceAll(',', ', ')}
                </span>
              </Tooltip>
            }
          >
            <span className="show-more-cameras ph-no-capture">
              {filteredCamera.slice(4, filteredCamera.length - 1).length}{' '}
              {i18n.t('list.others')}
            </span>
          </OverlayTrigger>
        )}
      </td>

      {/* <td className="text-capitalize">
        {i18n.t(filteredStatus ? filteredStatus.title : 'status.open')}
      </td> */}

      {/* <td onClick={() => navigation()}>
        <div className="d-flex align-items-center">
          <span
            className={`${priorityHighLevel} ${priorityMediumLevel} ${priorityLowLevel}`}
            style={{
              width: 6,
              height: 6,
              marginRight: 4,
            }}
          />
          <p className="m-0">{i18n.t(filteredPriority.length ? filteredPriority[0].title : priorityList[2].title)}</p>
        </div>
      </td> */}

      <td>
        <div
          className="d-flex justify-content-center align-items-center"
          aria-hidden
        >
          <CustomDropdownWithSubMenu
            mainClassName="position-absolute"
            dropdownData={dropdownList}
            hideContextData="ph-no-capture"
            isThreeDot
            icon={VerticalDotsIcon}
            variant={`outline-${theme}`}
            bodyVariant={theme}
            onClick={(selectValue) => {
              const { value, subMenu } = selectValue;
              const { id } = alert;
              if (selectValue.value === 'report') {
                onReportFalseModal(id);
              } else if (selectValue.value === 'view_details') {
                navigation();
              } else {
                onSensitiveAlertAction(
                  id,
                  String(value),
                  String(subMenu ? subMenu[0].value : ''),
                );
              }
            }}
          />
        </div>
      </td>
    </tr>
  );
}
