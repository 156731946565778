import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAuthContext } from '../contextapi/AuthProvider';
import { CacheProvider } from '../contextapi/CacheProvider';
import { CameraProvider } from '../contextapi/CameraProvider';
import { ScenarioProvider } from '../contextapi/ScenarioProvider';
import { TimeRangeProvider } from '../contextapi/TimeRangeProvider';
import { useScrollToTop } from '../hooks/window';
import { ProtectedLayout } from '../layouts/ProtectedLayout';
import { AlertDetailPage } from '../pages/AlertDetail';
import { CameraAnalyticsPage } from '../pages/CameraAnalyticsPage';
import { CameraDetailsPage } from '../pages/CameraDetailsPage';
import { CamerasPage } from '../pages/CamerasPage';
import { CreateAlertRulePage } from '../pages/CreateAlertRule';
import { DashboardPage } from '../pages/Dashboard';
import { ForgotPasswordPage } from '../pages/ForgotPassword';
import { GroupDetailPage } from '../pages/GroupDetailPage';
import { GroupObservationsPage } from '../pages/GroupObservationsPage';
import { LoadingPage } from '../pages/LoadingPage';
import { LoginPage } from '../pages/Login';
import { ObservationDetailPage } from '../pages/ObservationDetailPage';
import { ObservationListPage } from '../pages/ObservationListPage';
import { OldCreateAlertRulePage } from '../pages/OldCreateAlertRule';
import { OldSmartAlertsPage } from '../pages/OldSmartAlerts';
import { ProfilePage } from '../pages/ProfilePage';
import { SettingsPage } from '../pages/SettingsPage';
import { SmartAlertsPage } from '../pages/SmartAlertsPage';
import { UpdateAlertRulePage } from '../pages/UpdateAlertRule';

export function Router() {
  const {
    userCookie: { userToken },
    user,
    isUserLoading,
    featureFlags,
  } = useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useScrollToTop();

  useEffect(() => {
    if (
      !userToken &&
      pathname !== '/login' &&
      pathname !== '/forgot-password'
    ) {
      navigate('/login');
    }
  }, [userToken, pathname, navigate]);

  if (!userToken) {
    return (
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  if (isUserLoading || !user) {
    return <LoadingPage />;
  }

  const showGrouping = featureFlags.observations.show_grouping;
  const showDashboard = featureFlags.dashboard.show_page;

  return (
    <CacheProvider>
      <TimeRangeProvider>
        <CameraProvider>
          <ScenarioProvider>
            <Routes>
              <Route element={<ProtectedLayout />}>
                <Route
                  path="/dashboard"
                  element={
                    showDashboard ? (
                      <DashboardPage />
                    ) : showGrouping ? (
                      <GroupObservationsPage />
                    ) : (
                      <ObservationListPage />
                    )
                  }
                />

                <Route path="/observations" element={<ObservationListPage />} />
                <Route
                  path="/group-observations/:groupId"
                  element={<GroupDetailPage />}
                />

                <Route
                  path="/observation/:slug"
                  element={<ObservationDetailPage />}
                />
                <Route path="/cameras" element={<CamerasPage />} />
                <Route
                  path="/camera/:cameraId"
                  element={<CameraDetailsPage />}
                />
                <Route
                  path="/camera-analytics/:slug"
                  element={<CameraAnalyticsPage />}
                />

                <Route
                  path="/smart-notifications"
                  element={<OldSmartAlertsPage />}
                />
                <Route
                  path="/create-rule"
                  element={<OldCreateAlertRulePage />}
                />
                <Route
                  path="/edit-rule/:slug"
                  element={<OldCreateAlertRulePage />}
                />

                <Route path="/alerts" element={<SmartAlertsPage />} />
                <Route
                  path="/alerts/create"
                  element={<CreateAlertRulePage />}
                />
                <Route
                  path="/alerts/edit/:slug"
                  element={<UpdateAlertRulePage />}
                />
                <Route path="/alerts/:slug" element={<AlertDetailPage />} />

                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/profile/:slug" element={<ProfilePage />} />

                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Route>
            </Routes>
          </ScenarioProvider>
        </CameraProvider>
      </TimeRangeProvider>
    </CacheProvider>
  );
}
