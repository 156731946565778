import React from 'react';
import { Col } from 'react-bootstrap';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  OBSERVATION_STATUSES,
  TitleAndValue,
} from '../../../typescript/observation/observation';
import i18n from '../../../utils/i18n';
import { CustomDropdown } from '../../elements/CustomDropdown';

interface Props {
  statusOfObservations: TitleAndValue | null;
  onClick: (status: TitleAndValue) => void;
}

export function StatusFilter({ statusOfObservations, onClick }: Props) {
  const { theme } = useThemeContext();

  return (
    <Col md={12}>
      <hr />
      <p className="mb-1 weight-600 ">{i18n.t('filter.status.title')}</p>
      <p className="sub-color">{i18n.t('filter.status.body')}</p>
      <CustomDropdown
        title={
          !statusOfObservations
            ? `${i18n.t('button.select_status')}`
            : `${i18n.t(statusOfObservations.title)}`
        }
        className="me-2"
        dropdownData={OBSERVATION_STATUSES.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.title === value.title),
        ).map((item: TitleAndValue) => ({
          title: `${i18n.t(item.title)}`,
          value: item.value,
        }))}
        variant={`outline-${theme}`}
        bodyVariant={theme}
        onClick={(value) =>
          onClick({ title: String(value.title), value: value.value })
        }
      />
    </Col>
  );
}
