/* eslint-disable no-mixed-operators */
/* eslint-disable no-nested-ternary */
import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import AlertAction from './multiAction/AlertAction';
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg';
import { useThemeContext } from '../../contextapi/ThemeProvider';
import { ALERT_SITE_CHANNEL } from '../../graphql/queries/alerts';
import {
  ActionProps,
  MultiActionProps,
} from '../../typescript/smart-alert/smart-alert';
import i18n from '../../utils/i18n';
import { Button } from '../elements/Button';

type Props = {
  className?: string | undefined;
  selectedActionList: Array<MultiActionProps | undefined> | undefined;
  onAction: (action: Array<MultiActionProps | undefined>) => void;
};

export function MultiAlertAction({
  className,
  selectedActionList,
  onAction,
}: Props) {
  const { theme } = useThemeContext();
  const [actionList, setActionList] = useState<Array<ActionProps>>([]);

  const { data } = useQuery(ALERT_SITE_CHANNEL);

  useEffect(() => {
    if (data) {
      setActionList(data.alert_site_channel);
    }
  }, [data]);
  const list = selectedActionList as Array<MultiActionProps>;
  const actionIndex = list.length - 1;
  const isUserSelected = !list[actionIndex]
    ? true
    : list[actionIndex].userIds
      ? list[actionIndex].userIds?.length === 0
      : true;
  const isIotSelected = !list[actionIndex]
    ? true
    : list[actionIndex].iot
      ? list[actionIndex].iot?.length === 0
      : true;

  return (
    <Col md={12} className={className}>
      <p className="mb-1 weight-600">{i18n.t('alert_action.title')}</p>
      <p className="sub-color">{i18n.t('alert_action.body')}</p>
      {selectedActionList?.map((item, index) => {
        if (item) {
          return (
            <AlertAction
              key={item.action.id}
              className={`action-item ${index !== selectedActionList.length - 1 && 'mb-12'}`}
              actionList={actionList.filter(
                (o1) =>
                  !selectedActionList
                    .slice(0, index)
                    .some((o2) => o1.id === o2?.action.id),
              )}
              selectedAction={item}
              onAction={(action) => {
                const updatedList = [...selectedActionList];
                updatedList[index] = action;
                onAction(updatedList);
              }}
              onDelete={(action) => {
                const updatedList = selectedActionList.filter(
                  (filter) => filter !== action,
                );
                onAction(updatedList.length === 0 ? [undefined] : updatedList);
              }}
            />
          );
        }
        return (
          <AlertAction
            key={String(index + 1)}
            className="action-item"
            actionList={actionList.filter(
              (o1) =>
                !selectedActionList
                  .slice(0, index)
                  .some((o2) => o1.id === o2?.action.id),
            )}
            selectedAction={item}
            onAction={(action) => {
              const updatedList = [
                ...selectedActionList.slice(0, index),
                action,
              ];
              onAction(updatedList);
            }}
          />
        );
      })}
      <Button
        disabled={
          (isUserSelected && isIotSelected) || selectedActionList?.length === 3
        }
        icon={AddIcon}
        className="mt-12"
        variant={`outline-${theme}`}
        type="button"
        title={i18n.t('button.add_action')}
        onClick={() => {
          if (selectedActionList) {
            const addNewAction = [...selectedActionList, undefined];
            onAction(addNewAction);
          }
        }}
      />
    </Col>
  );
}
