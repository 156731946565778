import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { DangerZoneModal } from './danger-zone/DangerZoneModal';
import { NoScenarioModal } from './danger-zone/NoScenarioModal';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus.svg';
import { useCameraContext } from '../../../contextapi/CameraProvider';
import { useScenarioContext } from '../../../contextapi/ScenarioProvider';
import { useThemeContext } from '../../../contextapi/ThemeProvider';
import {
  getCameraScenarios,
  sortScenarios,
  supportsDangerZone,
} from '../../../lib/helpers/scenario';
import i18n from '../../../utils/i18n';
import { Button } from '../../elements/Button';

interface Props {
  hideParentModal: () => void;
}

export function DangerZoneSettings({ hideParentModal }: Props) {
  const { theme } = useThemeContext();
  const { gqlCamera } = useCameraContext();
  const { userScenarios } = useScenarioContext();

  const [show, setShow] = useState<boolean>(false);

  const activeScenarios = getCameraScenarios(userScenarios, gqlCamera);
  const supportedScenarios = sortScenarios(
    activeScenarios.filter(supportsDangerZone),
  );

  const handleCloseModal = () => {
    hideParentModal();
    setShow(!show);
  };

  return (
    <Col md={12}>
      <p className="mb-1 weight-600">{i18n.t('camera.danger_zone.title')}</p>
      <p className="sub-color">{i18n.t('camera.danger_zone.body')}</p>

      <p className="fw-bold">
        {supportedScenarios
          .map((scenario) => i18n.t(scenario.title))
          .join(', ')}
      </p>

      <Button
        icon={AddIcon}
        variant={`outline-${theme}`}
        type="button"
        title={i18n.t('button.danger_zone')}
        onClick={handleCloseModal}
      />

      {supportedScenarios.length > 0 ? (
        <DangerZoneModal show={show} onClose={handleCloseModal} />
      ) : (
        <NoScenarioModal show={show} onButtonClick={handleCloseModal} />
      )}

      <hr />
    </Col>
  );
}
